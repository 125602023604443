var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "ชื่อผู้ใช้"
          }
        },
        [
          _c(
            "a-input",
            {
              directives: [
                {
                  name: "decorator",
                  rawName: "v-decorator",
                  value: [
                    "username",
                    {
                      rules: [
                        {
                          required: _vm.isCreateForm,
                          message: "กรุณากรอก ชื่อผู้ใช้ ด้วยค่ะ"
                        },
                        {
                          validator: _vm.isCreateForm
                            ? _vm.usernameValidator
                            : null
                        }
                      ]
                    }
                  ],
                  expression:
                    "[\n                'username',\n                { rules:\n                    [\n                        { required: isCreateForm, message: 'กรุณากรอก ชื่อผู้ใช้ ด้วยค่ะ' },\n                        { validator: isCreateForm ? usernameValidator : null }\n                    ]\n                }\n            ]"
                }
              ],
              attrs: { disabled: !_vm.isCreateForm, placeholder: "ชื่อผู้ใช้" }
            },
            [
              _c("a-icon", {
                attrs: { slot: "prefix", type: "user" },
                slot: "prefix"
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.isCreateForm || _vm.enableChangePassword
        ? [
            _c(
              "a-form-item",
              {
                attrs: {
                  "label-col": { span: 4 },
                  "wrapper-col": { span: 12 },
                  label: "รหัสผ่าน"
                }
              },
              [
                _c(
                  "a-input",
                  {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "password",
                          {
                            rules: [
                              {
                                required: _vm.isCreateForm,
                                message: "กรุณากรอก รหัสผ่าน ด้วยค่ะ"
                              },
                              { validator: _vm.passwordValidator }
                            ]
                          }
                        ],
                        expression:
                          "[\n                    'password',\n                    {\n                        rules: [ {\n                                     required: isCreateForm,\n                                     message: 'กรุณากรอก รหัสผ่าน ด้วยค่ะ'\n                                 },\n                                 { validator: passwordValidator } ]\n                    } ]"
                      }
                    ],
                    attrs: { type: "password", placeholder: "รหัสผ่าน" }
                  },
                  [
                    _c("a-icon", {
                      attrs: { slot: "prefix", type: "lock" },
                      slot: "prefix"
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "a-form-item",
              { attrs: { label: "บังคับให้ตั้งรหัสผ่านใหม่" } },
              [
                _c("a-checkbox", {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: [
                        "requireChangePasswd",
                        {
                          initialValue: true,
                          valuePropName: "checked",
                          rules: [
                            {
                              required: true,
                              message: "กรุณากรอก รหัสผ่าน ด้วยค่ะ"
                            }
                          ]
                        }
                      ],
                      expression:
                        "[ 'requireChangePasswd',\n                               {\n                                   initialValue: true,valuePropName: 'checked',\n                                   rules: [\n                                       { required: true, message: 'กรุณากรอก รหัสผ่าน ด้วยค่ะ' }\n                                   ] },\n                ]"
                    }
                  ]
                }),
                _vm.form.getFieldValue("requireChangePasswd") === false
                  ? [
                      _c("br"),
                      _c("a-icon", {
                        style: { "font-size": "18px" },
                        attrs: {
                          type: "warning",
                          theme: "twoTone",
                          "two-tone-color": "#faad14"
                        }
                      }),
                      _vm._v(
                        "\n                จะไม่บังคับให้ตั้งรหัสผ่านใหม่\n                ก็ต่อเมื่อเป็น "
                      ),
                      _c("strong", [
                        _vm._v(
                          "ผู้ใช้ที่ไม่มีความรู้เรื่องเทคโนโลยีเลยเท่านั้น"
                        )
                      ])
                    ]
                  : _vm._e()
              ],
              2
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }