var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h3", [_vm._v("ประเภทสินค้า")]),
      _c("strong", [_vm._v("ตัวบนสุด ")]),
      _vm._v("จะเป็น "),
      _c("strong", [_vm._v("ตัวหลักที่ใช้ในแสดงรายละเอียด")]),
      _vm._v("\n    และจะ "),
      _c("strong", [_vm._v("ถูกเลือกโดยเริ่มต้นเวลากดซื้อ")]),
      _c("br"),
      _vm._l(_vm.form.getFieldValue("keys"), function(k) {
        return _c(
          "a-form-item",
          _vm._b(
            { key: k, attrs: { required: false } },
            "a-form-item",
            _vm.formItemLayout,
            false
          ),
          [
            _c(
              "a-card",
              [
                k >= 1
                  ? _c(
                      "a-popconfirm",
                      {
                        attrs: { title: "ยืนยันการลบ?" },
                        on: {
                          confirm: function() {
                            return _vm.remove(k)
                          }
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "product-variant-form-items__remove-button",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                    ลบ\n                    "
                            ),
                            k >= 1
                              ? _c("a-icon", {
                                  staticClass: "dynamic-delete-button",
                                  style: { fontSize: "16px" },
                                  attrs: { type: "delete" }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e(),
                _c(
                  "a-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false"
                      }
                    ]
                  },
                  [
                    _vm.isUpdateForm
                      ? _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: ["variants[" + k + "][id]"],
                              expression:
                                "\n                        [ `variants[${k}][id]` ]"
                            }
                          ]
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "a-form-item",
                  {
                    attrs: {
                      "label-col": { span: 4 },
                      "wrapper-col": { span: 12 },
                      label: "ชื่อประเภท"
                    }
                  },
                  [
                    _c("a-input", {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "variants[" + k + "][name]",
                            {
                              rules: [
                                {
                                  required: true,
                                  message: "กรุณาใส่ค่าช่องนี้ด้วยค่ะ"
                                }
                              ]
                            }
                          ],
                          expression:
                            "\n                        [ `variants[${k}][name]`,\n                          {\n                              rules:\n                                  [\n                                      { required: true,\n                                        message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', } ] } ]"
                        }
                      ],
                      attrs: { disabled: _vm.disabled }
                    })
                  ],
                  1
                ),
                _c(
                  "a-form-item",
                  {
                    attrs: {
                      label: "จำนวนสินค้าต่อชุด",
                      help: "เช่น ถ้าขายเป็นคู่ ก็คือ จำนวนสินค้าต่อชุด = 2",
                      "label-col": { span: 4 },
                      "wrapper-col": { span: 12 }
                    }
                  },
                  [
                    _c("a-input-number", {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "variants[" + k + "][amountPerUnit]",
                            {
                              rules: [
                                {
                                  required: true,
                                  message: "กรุณาใส่ค่าช่องนี้ด้วยค่ะ"
                                }
                              ]
                            }
                          ],
                          expression:
                            "\n                        [ `variants[${k}][amountPerUnit]`,\n                          {\n                              rules:\n                                  [\n                                      { required: true,\n                                        message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', },\n                                  ] } ]"
                        }
                      ],
                      attrs: { disabled: _vm.disabled, min: 1 }
                    }),
                    _vm.form.getFieldValue("variants[" + k + "][amountPerUnit]")
                      ? [
                          _vm._v(
                            "\n                    = ซื้อ 1 ชุด\n                    "
                          ),
                          _c("a-icon", { attrs: { type: "arrow-right" } }),
                          _vm._v(
                            "\n                    ได้\n                    "
                          ),
                          _c("strong", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.form.getFieldValue(
                                    "variants[" + k + "][amountPerUnit]"
                                  )
                                ) +
                                "\n                    "
                            )
                          ]),
                          _vm._v(" ชิ้น (ใช้เล่นได้\n                    "),
                          _c("strong", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.form.getFieldValue(
                                    "variants[" + k + "][amountPerUnit]"
                                  )
                                ) +
                                "\n                    "
                            )
                          ]),
                          _vm._v("\n                    คน)\n                ")
                        ]
                      : _vm._e()
                  ],
                  2
                ),
                _c("ProductVariantPricingsFormItems", {
                  attrs: {
                    "is-update-form": _vm.isUpdateForm,
                    "index-variant": k,
                    form: _vm.form,
                    data: _vm.data
                  }
                })
              ],
              1
            )
          ],
          1
        )
      }),
      _c(
        "a-form-item",
        _vm._b({}, "a-form-item", _vm.formItemLayoutWithOutLabel, false),
        [
          _c(
            "a-button",
            {
              staticStyle: { width: "60%" },
              attrs: { disabled: _vm.disabled, type: "dashed" },
              on: { click: _vm.add }
            },
            [
              _c("a-icon", { attrs: { type: "plus" } }),
              _vm._v(" เพิ่มประเภทสินค้า\n        ")
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }