import EntitiesPage from '@/mixins/EntitiesPage'

export default {
    mixins: [EntitiesPage],
    data () {
        return {
            entityIds: [],
            newEntityIds: [],
            invertOrder: false,
            isResetOnLoadMore: false,
        }
    },
    computed: {
    },
    methods: {
        getAllEntities () {
            if (process.env.NODE_ENV !== 'production') {
                // eslint-disable-next-line no-console
                console.error(('[mixins: EntitiesWithLazyLoad] Please overide getAllEntities() method.'))
            }
        },
        async fetchEntityWithLazyLoad () {
            if (process.env.NODE_ENV !== 'production') {
                // eslint-disable-next-line no-console
                console.error(('[mixins: EntitiesWithLazyLoad] Please overide fetchEntityWithLazyLoad() method.'))
            }
        },
        onCreateEntityWithLazyLoad (entity) {
            this.newEntityIds = this.invertOrder
                ? [...this.newEntityIds, entity.id]
                : [entity.id, ...this.newEntityIds]
            this.onCreateEntity(entity)
        },
        // eslint-disable-next-line no-unused-vars
        async onCreateEntity (_entity) {
            this.closeCreateForm()
        },
        async onDeleteEntityWithLazyLoad (entityId) {
            await this.onDelete(entityId)
            this.removeEntity()
        },
        // Remove entity but not delete entity
        async removeEntity (entityId) {
            this.entityIds = this.entityIds.filter((id) => id !== entityId)
            this.newEntityIds = this.newEntityIds.filter((id) => id !== entityId)
        },
        callbackSuccessFetchEntities () {
            // for run post process
        },
        onSuccessFetchEntitiesWithLazyLoad (res) {
            if (this.isResetOnLoadMore) {
                // reset on page change
                this.newEntityIds = []
                this.entityIds = []
            }
            const { datas } = res.data
            const oldExcludeNew = this.entityIds.filter((id) => datas.findIndex((data) => data.id === id) === -1)
            this.entityIds = this.invertOrder
                ? [...datas.map((data) => data.id).reverse(), ...oldExcludeNew]
                : [...oldExcludeNew, ...datas.map((data) => data.id)]
            this.callbackSuccessFetchEntities()
        },
        onSuccessFetchEntities (res) {
            this.onSuccessFetchEntitiesWithLazyLoad(res)
        },
        fetchEntities () {
            return this.fetchEntityWithLazyLoad()
        },
        getEntities () {
            return this.mapEntityIdsToEntities(this.entityIds)
        },
        getNewEntities () {
            return this.mapEntityIdsToEntities(this.newEntityIds)
        },
        mapEntityIdsToEntities (ids) {
            return ids.map((id) => this.getAllEntities().find(
                (e) => e.id === id,
            )).filter(
                (e) => typeof e !== 'undefined',
            )
        },
    },
}
