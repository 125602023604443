export default {
    methods: {
        getMinPayAtStadiumPrice (onlinePrice) {
            return parseFloat(
                (onlinePrice * (1 + (this.addPercentForPayAtStadium / 100))).toFixed(2),
            )
        },
        getPropNameVariantWithIndexFromRule (rule) {
            return `${rule.field.split(']')[0]}]`
        },
        validateFullPrice (rule, value, callback) {
            const variant = this.getPropNameVariantWithIndexFromRule(rule)
            const fullPrice = this.form.getFieldValue(`${variant}[${this.propNameFullPrice}]`)
            const onlinePrice = this.form.getFieldValue(`${variant}[${this.propNameOnlinePrice}]`)
            if (value != null && fullPrice <= onlinePrice) {
                return callback('ราคาก่อนโปรโมชั่น ต้องมากกว่า ราคาโปรโมชั่น  ')
            }
            return callback()
        },
        validatePayAtStadiumPrice (rule, value, callback) {
            const variant = this.getPropNameVariantWithIndexFromRule(rule)
            const errorMsg = this.getErrorMsgOnPayOfflinePrice(variant, value)
            if (errorMsg !== null) return callback(errorMsg)
            return callback()
        },
        validatePrice (rule, value, callback) {
            const variant = this.getPropNameVariantWithIndexFromRule(rule)
            const fullPrice = this.form.getFieldValue(`${variant}[${this.propNameFullPrice}]`)
            if (typeof value !== 'number') return callback('ต้องเป็นตัวเลขเท่านั้น')
            if (value && fullPrice < value) {
                return callback('ราคาโปรโมชั่น ต้อง <= ราคาก่อนโปรโมชั่น')
            }
            return callback()
        },
        triggerValidatePrice (rule, value, callback) {
            const variant = this.getPropNameVariantWithIndexFromRule(rule)
            this.form.validateFields([`${variant}[${this.propNameOnlinePrice}]`], {
                force: true,
            },
            () => {
                // Put empty function to prevent echo errors.
            })
            callback()
        },
        getErrorMsgOnPayOfflinePrice (variant, value) {
            if (!value && value !== 0) return null
            if (typeof value !== 'number') return 'ต้องเป็นตัวเลขเท่านั้น'

            const onlinePrice = this.form.getFieldValue(`${variant}[${this.propNameOnlinePrice}]`)
            const fullPrice = this.form.getFieldValue(`${variant}[${this.propNameFullPrice}]`)
            // TODO: We will force price to more than 10% when this discount are perform by stadium.
            // But now, we spend discount instead of stadium
            // So sometime we can't effort to pay to much discount.
            // Because of this, we currently will not fixed 10% discount
            // const minPrice = this.getMinPayAtStadiumPrice(onlinePrice)
            const minPrice = onlinePrice
            if (value != null
                && value < minPrice
            ) {
                return `ต้อง >= ${minPrice}
                (ราคาจ่ายเงินที่สนาม ต้องมากกว่า จ่ายออนไลน์ ${this.addPercentForPayAtStadium}%)
`
            }
            if (value != null && (value >= fullPrice)) {
                return 'ราคาจ่ายเงินที่สนาม ต้องน้อยกว่า ราคาก่อนโปรโมชั่น'
            }
            return null
        },
    },

}
