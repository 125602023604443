<template>
    <div class="section-new">
        <TagNew />
        <slot />
    </div>
</template>
<script>
import TagNew from '@/components/TagNew.vue'

export default {
    components: {
        TagNew,
    },
}
</script>
<style lang="stylus">
.section-new
    background-color #fffbe6
    padding 8px
    margin-bottom 16px
    clear: both
</style>
