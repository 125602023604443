<template>
    <div>
        <a-form-item
            :label="labelStartTime"
            has-feedback="has-feedback"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }">
            <a-date-picker
                v-decorator="[ namePropStartTime ,
                               {
                                   normalize: normallizeDateTime,
                                   rules:
                                       [
                                           { required: required,
                                             message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', }
                                           ,{ validator: triggerValidateEndTimeField }
                                       ] } ]"
                :show-time="startOptions"
                :show-today="false"
                :format="datetimeFormat"
                :placeholder="labelStartTime"
                :open="startTimePickerOpen"
                :disabled="disabled"
                @change="handleStartChange"
                @openChange="handleStartOpenChange" />
        </a-form-item>
        <a-form-item
            :label="labelEndTime"
            has-feedback="has-feedback"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }">
            <a-date-picker
                v-decorator="[ namePropEndTime ,
                               {
                                   normalize: normallizeDateTime,
                                   rules:
                                       [
                                           { required: required,
                                             message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', },{
                                                 validator: validateStartTimeIsBeforeEndTime
                                             }
                                       ] } ]"
                :disabled="disabled"
                :disabled-date="disabledEndDate"
                :disabled-time="disabledEndTime"
                :show-time="endOptions()"
                :show-today="false"
                :format="datetimeFormat"
                :placeholder="labelEndTime"
                :open="endTimePickerOpen"
                @change="handleEndChange"
                @openChange="handleEndOpenChange" />
        </a-form-item>
    </div>
</template>

<script>
import moment from 'moment'
import MixinFormItemRangeTime from '@/mixins/MixinFormItemRangeTime'
import MixinDateTimePicker from '@/mixins/MixinDateTimePicker'

export default {
    mixins: [MixinFormItemRangeTime, MixinDateTimePicker],
    props: {
        isPreventStartBeforeEnd: {
            type: Boolean,
            default: true,
        },
        namePropStartTime: {
            type: String,
            default: 'start',
        },
        namePropEndTime: {
            type: String,
            default: 'end',
        },
        required: {
            type: Boolean,
            default: true,
        },
    },
    data () {
        return {
            datetimeFormat: 'DD-MM-YYYY | HH:mm ',
            timeOptions: {
                minuteStep: this.minuteStep,
                format: 'HH:mm',
                defaultValue: moment('00:00:00', 'HH:mm:ss'),
            },
        }
    },
    computed: {
        startOptions () {
            return this.timeOptions
        },
    },
    methods: {
        endOptions () {
            return {
                ...this.timeOptions,
            }
        },
    },
}
</script>
