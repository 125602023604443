var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "v-form-item-cover-photo" },
    [
      _c("VFormItemPhotoVue", {
        attrs: {
          form: _vm.form,
          data: _vm.data,
          width: _vm.ImageSize.w,
          height: _vm.ImageSize.h,
          label: "รูปภาพปก (Cover Photo) ",
          "prop-name": "coverPhotoUrl"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }