<template>
    <div>
        <img
            v-if="relativePath"
            class="vIcon"
            alt="icon"
            :src="fullPath">
        <div v-else>
            <a-icon type="plus" view-box="0 0 1024 1024" />
        </div>
    </div>
</template>
<script>
export default {
    props: {
        relativePath: {
            type: String,
            default: null,
        },
    },
    computed: {
        fullPath () {
            return this.relativePath
        },
    },
}
</script>
<style lang="stylus">
.vIcon
    width: 84px;
    height: 84px;
    border-radius: 100%;
    object-fit: cover;
</style>
