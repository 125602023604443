<template>
    <div>
        <a-form-item
            label="ราคาก่อนโปรโมชั่น"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }">
            <a-input-number
                v-decorator="
                    [ `variants[${indexVariant}][${propNameFullPrice}]`,
                      { rules:
                          [
                              { required: true,
                                message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', },
                              { validator: triggerValidatePrice },
                              { validator: validateFullPrice },
                          ] } ]"
                :disabled="disabled"
                :min="0" />
            = {{ getFullPrice()
                | formatCurrency }}
        </a-form-item>
        <strong>ราคาโปรโมชั่น</strong>
        <br>
        <br>
        <a-form-item
            label="ราคาจ่ายออนไลน์"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }">
            <a-input-number
                v-decorator="
                    [ `variants[${indexVariant}][${propNameOnlinePrice}]`,
                      { rules:
                          [
                              { required: true,
                                message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', },
                              { validator: validatePrice },
                          ] } ]"
                :disabled="disabled"
                :min="0"
                :precision="2"
                @change="(value)=>onUpdateOnlinePrice(indexVariant,value)" />
            = {{ getOnlinePrice()
                | formatCurrency }}
            <FeeDesc :price="getOnlinePrice()" />
        </a-form-item>
        <div
            v-for="method in PaymentMethod"
            :key="method">
            <a-form-item
                v-show="method === PaymentMethod.PAY_AT_STADIUM"
                :label="getMsgFromPaymentMethod(method)"
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 12 }">
                <a-switch
                    :checked="isEnablePaymentMethod(indexVariant,method)"
                    @change="(checked)=>onEnablePaymentMethod(indexVariant,method,checked)" />
                <div
                    v-if="isEnablePaymentMethod(indexVariant,method)">
                    <a-input-number
                        v-decorator="
                            [ `${getVariantPricingPropName(indexVariant,method)}[price]`,
                              { initialValue: getDefaultPriceOfPaymentMethod(indexVariant,method),
                                rules:
                                    [ { required: isEnablePaymentMethod(indexVariant,method),
                                        message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', },
                                      { validator: method === PaymentMethod.PAY_AT_STADIUM
                                          ? validatePayAtStadiumPrice : validatePrice }
                                    ] } ]"
                        :disabled="disabled"
                        :precision="2"
                        :min="0" />
                    = {{ getPriceOfMethod(method) | formatCurrency }}
                </div>
            </a-form-item>
            <a-form-item
                v-show="false"
                :label="method"
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 12 }">
                <a-input
                    v-decorator="
                        [ `${getVariantPricingPropName(indexVariant,method)}[paymentMethod]`,
                          { initialValue: method ,
                            rules:
                                [ { required: isEnablePaymentMethod(indexVariant,method),
                                    message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', },
                                ] } ]" />
            </a-form-item>
        </div>
    </div>
</template>

<script>
import FormItems from '@/mixins/FormItems'
import Vue from 'vue'
import { getMsgFromPaymentMethod, getOnlinePaymentMethod, PaymentMethod } from '@/utils/enum'
import MixinFormItemVariantPricingsValidator from '@/mixins/MixinFormItemVariantPricingsValidator'
import MixinFormItemVariantPricingsKey from '@/mixins/MixinFormItemVariantPricingsKey'
import FeeDesc from '@/components/FeeDesc.vue'
import { isEmptyArray, isObjectEmpty } from '@/utils/utils'

export default {
    components: { FeeDesc },
    mixins: [
        FormItems,
        MixinFormItemVariantPricingsValidator,
        MixinFormItemVariantPricingsKey,
    ],
    props: {
        suffixLabelPrice: {
            type: String,
            default: '',
        },
        indexVariant: {
            type: Number,
            required: true,
        },
        isUpdateForm: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            addPercentForPayAtStadium: 10,
            PaymentMethod,
            propNameFullPrice: 'fullPrice',
            propNameOnlinePrice: 'minPrice',
            propNamePricings: 'pricings',
        }
    },
    computed: {
        isEmptyVariants () {
            return isObjectEmpty(this.data) || isEmptyArray(this.data.variants)
        },
        isCreateForm () {
            return !this.isUpdateForm
        },
    },
    mounted () {
        const propName = this.getVariantPricingKeysPropName(this.indexVariant)
        this.form.getFieldDecorator(propName,
            {
                initialValue: [],
                preserve: true,
            })
        const formItemData = this.isCreateForm
         && this.isEmptyVariants
            ? null : this.data.variants[this.indexVariant]
        if (!formItemData) {
            this.form.setFieldsValue({
                [propName]: getOnlinePaymentMethod(),
            })
        }
    },
    methods: {
        getMsgFromPaymentMethod,
        mapDataToFormItems (data) {
            if (this.isCreateForm && this.isEmptyVariants) return null
            const variant = data.variants[this.indexVariant]
            const index = this.indexVariant
            if (!variant) return null
            Object.keys(variant).forEach((key) => {
                if (key === this.propNamePricings) {
                    variant[this.propNamePricings].forEach((pricing, pricingIndex) => {
                        this.addVariantPricingKey(this.indexVariant, pricing.paymentMethod)
                        Object.keys(pricing).forEach((pricingKey) => {
                            this.form.getFieldDecorator(
                                `variants[${index}][${key}][${pricingIndex}][${pricingKey}]`,
                            )
                            this.form.setFieldsValue({
                                [`variants[${index}]`
                                + `[${key}]`
                                + `[${pricingIndex}]`
                                + `[${pricingKey}]`]: pricing[pricingKey],
                            })
                        })
                    })
                }
            })
            return {}
        },
        onEnablePaymentMethod (index, paymentMethod, checked) {
            if (checked) {
                this.addVariantPricingKey(index, paymentMethod)
            } else {
                this.removeVariantPricingKey(index, paymentMethod)
            }
        },
        onUpdateOnlinePrice (index, price) {
            Vue.nextTick(() => {
                Object.keys(PaymentMethod).forEach((method) => {
                    const key = this.getPricingMethodKeyFromEnableOnly(index, method)
                    if (key !== null && key !== undefined) {
                        // we doesn't enable to set custom price for online payment
                        // so we use assign this field value directly
                        let validPrice = price
                        const propNameVariantPrice = `${
                            this.getVariantPricingPropName(index, method)
                        }[price]`
                        if (method === PaymentMethod.PAY_AT_STADIUM) {
                            const currentPrice = this.form.getFieldValue(propNameVariantPrice)
                            const defaultPrice = this.getDefaultPriceOfPaymentMethod(index, method)
                            validPrice = Math.max(currentPrice, defaultPrice)
                        }
                        this.form.setFieldsValue({
                            [propNameVariantPrice]: validPrice,
                        })
                    }
                })
            })
        },
        getDefaultPriceOfPaymentMethod (indexVariant, paymentMethod) {
            const onlinePrice = this.form.getFieldValue(
                `variants[${indexVariant}][${this.propNameOnlinePrice}]`,
            )
            if (onlinePrice === undefined || onlinePrice === null) {
                return null
            }
            return paymentMethod === PaymentMethod.PAY_AT_STADIUM
                ? Math.ceil(this.getMinPayAtStadiumPrice(onlinePrice))
                : onlinePrice
        },
        getOnlinePrice () {
            const price = this.form.getFieldValue(
                `variants[${this.indexVariant}][${this.propNameOnlinePrice}]`,
            )
            if (price === undefined) return price
            if (price === null || price === '') {
                return null
            }
            return parseFloat(price)
        },
        getFullPrice () {
            const price = this.form.getFieldValue(
                `variants[${this.indexVariant}][${this.propNameFullPrice}]`,
            )
            if (price === undefined) return price
            if (price === null || price === '') {
                return null
            }
            return parseFloat(price)
        },
        getPriceOfMethod (method) {
            const price = this.form.getFieldValue(
                `${this.getVariantPricingPropName(this.indexVariant, method)}[price]`,
            )
            if (price === null || price === undefined) {
                return this.getDefaultPriceOfPaymentMethod(this.indexVariant, method)
            }
            return price
        },
        isEnablePaymentMethod (variantIndex, method) {
            const key = this.getPricingMethodKeyFromEnableOnly(variantIndex, method)
            return key !== null && key !== undefined
        },
        getVariantPricingPropName (indexVariant, method) {
            const key = this.getPricingMethodKeyFromEnableOnly(indexVariant, method)
            if (key == null) return null
            return `variants[${indexVariant}][${this.propNamePricings}][${key}]`
        },
    },
}
</script>
<style lang="stylus">
.product-variant-form-items__remove-button
    font-size: 12px
    line-height: 0
    padding: $padding = 6px
    position: absolute
    right: 0px
    top: 0
    z-index: 100
    width: auto
    display: inline-block
    cursor: pointer
    &:hover
        color: $primary-color

</style>
