var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "v-form-item-cover-photo" },
    [
      _c("VFormItemPhotoVue", {
        attrs: {
          "is-disable-default-max-width": "",
          form: _vm.form,
          data: _vm.data,
          width: _vm.ImageSize.w,
          height: _vm.ImageSize.h,
          label: _vm.label,
          "is-circle": true,
          "prop-name": _vm.propName
        },
        scopedSlots: _vm._u([
          {
            key: "placeholder",
            fn: function(ref) {
              var loading = ref.loading
              return [
                _c("a-icon", {
                  attrs: {
                    type: loading ? "loading" : "plus",
                    "view-box": "0 0 1024 1024"
                  }
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }