var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    {
      class: ["vcard", _vm.disabled ? "vcard--disabled" : null],
      style: { maxWidth: _vm.maxWidth },
      attrs: { hoverable: "hoverable" }
    },
    [
      _c(
        "template",
        { slot: "cover" },
        [
          _vm.enableDelete && !_vm.disabled
            ? _c(
                "a-popconfirm",
                {
                  attrs: { title: "ยืนยันการลบ?" },
                  on: {
                    visibleChange: function(visible) {
                      return _vm.setVisible(visible)
                    },
                    confirm: function() {
                      return _vm.deleteCard()
                    }
                  }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "vcard__close-button",
                      class: { active: _vm.isPopupVisible() },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                        }
                      }
                    },
                    [_c("a-icon", { attrs: { type: "close" } })],
                    1
                  )
                ]
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "vcard__cover-container" },
            [
              _c(
                "VImageVue",
                {
                  attrs: { src: _vm.coverPhotoUrl, ratio: _vm.coverPhotoRatio }
                },
                [
                  _c(
                    "div",
                    { staticClass: "vcard__placeholder-img" },
                    [
                      _c("a-icon", { attrs: { type: "warning" } }),
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.placeholderLabel) +
                          "\n                "
                      )
                    ],
                    1
                  )
                ]
              ),
              _vm._t("cover"),
              _vm.overlayBottomCover
                ? _c(
                    "div",
                    { staticClass: "vcard__overlay-bottom-cover" },
                    [_vm._t("overlayBottomCover")],
                    2
                  )
                : _vm._e()
            ],
            2
          )
        ],
        1
      ),
      _c(
        "a-skeleton",
        {
          attrs: {
            loading: !_vm.title,
            paragraph: { rows: 1 },
            active: "",
            avatar: ""
          }
        },
        [
          _c(
            "a-card-meta",
            { attrs: { title: _vm.title } },
            [
              _vm.avartar
                ? _c("a-avatar", {
                    staticClass: "vcard__avartar",
                    attrs: { slot: "avatar", src: _vm.avartar, size: "large" },
                    slot: "avatar"
                  })
                : _vm._e(),
              _vm.description
                ? _c(
                    "div",
                    {
                      staticClass: "vcard__desc",
                      attrs: { slot: "description" },
                      slot: "description"
                    },
                    [
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.description) +
                            "\n                "
                        )
                      ],
                      _vm._v("\n             \n            ")
                    ],
                    2
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }