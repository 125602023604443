<template>
    <div class="v-form-item-cover-photo">
        <VFormItemPhotoVue
            is-disable-default-max-width
            :form="form"
            :data="data"
            :width="ImageSize.w"
            :height="ImageSize.h"
            :label="label"
            :is-circle="true"
            :prop-name="propName">
            <template v-slot:placeholder="{ loading }">
                <a-icon :type="loading ? 'loading' : 'plus'" view-box="0 0 1024 1024" />
            </template>
        </VFormItemPhotoVue>
    </div>
</template>
<script>
import FormItems from '@/mixins/FormItems'
import VFormItemPhotoVue from '@/components/VFormItemPhoto .vue'
import { EntityImageSize } from '@/utils/enum'

export default {
    components: {
        VFormItemPhotoVue,
    },
    mixins: [FormItems],
    props: {
        label: {
            type: String,
            required: true,
        },
        propName: {
            type: String,
            default: 'logoPhotoUrl',
        },
    },
    data () {
        return {
            ImageSize: EntityImageSize.STADIUM_LOGO,
        }
    },
    methods: {
        mapDataToFormItems () {
            return {}
        },
    },
}
</script>
<style lang="stylus">
$logo_width = 128px
$logo_high = 128px
.stadium-info__logo-uploader > .ant-upload
    width: 128px;
    height: 128px;
    border-radius: 100%;
.stadium-info__logo-uploader .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
}
.stadium-info__logo-uploader > .ant-upload img {
    width: 128px;
    height: 128px;
    border-radius: 100%;
    object-fit: cover;
}
</style>
