import { isEmpty } from '@/utils/utils'

export default {
    methods: {
        normallizeDateTime (dateTime) {
            if (isEmpty(dateTime)) return dateTime
            dateTime.set({ second: 0, millisecond: 0 })
            return dateTime
        },
    },
}
