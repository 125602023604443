import { Actions } from '@/store/types'
import VApiResponse from '@/utils/VApiResponse'
import { logError } from '@/utils/utils'

export default {
    props: {
        data: {
            type: Object,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        const initForm = this.$form.createForm(this)
        return {
            form: { ...initForm, _data: { ...initForm._data, disabled: this.disabled } },
            isProcessing: false,
        }
    },
    methods: {
        // eslint-disable-next-line no-unused-vars
        async submit (values) {
            if (process.env.NODE_ENV !== 'production') {
                // eslint-disable-next-line no-console
                console.error(('[mixins:Form] Please overide submit() method.'))
            }
        },
        handleSubmit (e) {
            e.preventDefault()
            this.form.validateFieldsAndScroll({
                scroll: {
                    onlyScrollIfNeeded: false,
                    alignWithTop: true,
                    offsetTop: 120,
                },
            }, async (err, values) => {
                if (!err) {
                    this.isProcessing = true
                    try {
                        const res = await this.submit(values)
                        if (res instanceof VApiResponse) {
                            if (res.isSuccess()) {
                                this.$emit('success', res.data)
                            } else {
                                this.$emit('error', res.data)
                            }
                        } else {
                            this.$emit('success', res)
                        }
                    } catch (submitError) {
                        this.$emit('error', submitError)
                        this.$store.dispatch(
                            Actions.message.SHOW_ERROR_MSG,
                            {
                                caller: 'Submit Form',
                                callerPayload: values,
                                data: submitError,
                            },
                        )
                        logError(submitError, 'Submit Form Error')
                    }

                    this.isProcessing = false
                }
            })
        },
    },
}
