<template>
    <div>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="ชื่อผู้ใช้">
            <a-input
                v-decorator="[
                    'username',
                    { rules:
                        [
                            { required: isCreateForm, message: 'กรุณากรอก ชื่อผู้ใช้ ด้วยค่ะ' },
                            { validator: isCreateForm ? usernameValidator : null }
                        ]
                    }
                ]"
                :disabled="!isCreateForm"
                placeholder="ชื่อผู้ใช้">
                <a-icon
                    slot="prefix"
                    type="user" />
            </a-input>
        </a-form-item>
        <template v-if="isCreateForm || enableChangePassword">
            <a-form-item
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 12 }"
                label="รหัสผ่าน">
                <a-input
                    v-decorator="[
                        'password',
                        {
                            rules: [ {
                                         required: isCreateForm,
                                         message: 'กรุณากรอก รหัสผ่าน ด้วยค่ะ'
                                     },
                                     { validator: passwordValidator } ]
                        } ]"
                    type="password"
                    placeholder="รหัสผ่าน">
                    <a-icon
                        slot="prefix"
                        type="lock" />
                </a-input>
            </a-form-item>
            <a-form-item
                label="บังคับให้ตั้งรหัสผ่านใหม่">
                <a-checkbox
                    v-decorator="[ 'requireChangePasswd',
                                   {
                                       initialValue: true,valuePropName: 'checked',
                                       rules: [
                                           { required: true, message: 'กรุณากรอก รหัสผ่าน ด้วยค่ะ' }
                                       ] },
                    ]" />
                <template
                    v-if="form.getFieldValue('requireChangePasswd') === false">
                    <br>
                    <a-icon
                        type="warning"
                        theme="twoTone"
                        :style="{ 'font-size': '18px' }"
                        two-tone-color="#faad14" />
                    จะไม่บังคับให้ตั้งรหัสผ่านใหม่
                    ก็ต่อเมื่อเป็น <strong>ผู้ใช้ที่ไม่มีความรู้เรื่องเทคโนโลยีเลยเท่านั้น</strong>
                </template>
            </a-form-item>
        </template>
    </div>
</template>
<script>
import FormItems from '@/mixins/FormItems'
import VPermission from '@/mixins/VPermission'

export default {
    mixins: [FormItems, VPermission],
    props: {
        isCreateForm: {
            required: true,
            type: Boolean,
        },
        enableChangePassword: {
            required: false,
            type: Boolean,
        },
    },
    data () {
        return {
            // alphanumeric and _.
            validUsernameCharacters: /^[a-zA-Z0-9_.]+$/,
            validUsernameStartCharacters: /[a-zA-Z0-9]/,
            validPasswordCharacters: /^[a-zA-Z0-9_.!@#$%^&*]+$/,
        }
    },
    methods: {
        mapDataToFormItems (data) {
            let map = {
                username: data.username,
            }
            const mapPasswordFormFields = {
                requireChangePasswd: data.requireChangePasswd,
            }
            if (this.enableChangePassword) {
                map = { ...map, ...mapPasswordFormFields }
            }
            return map
        },
        usernameValidator (rule, value, callback) {
            if (value) {
                if (!value.match(this.validUsernameStartCharacters)) {
                    return callback('ตัวอักษรแรกต้องเป็น (a-z0-9) เท่านั้น')
                }
                if (!value.match(this.validUsernameCharacters)) {
                    return callback('ตัวอักษรที่ใช้ต้องเป็น (a-z0-9_.) เท่านั้น')
                }
                if (value.length < 6 || value.length > 20) {
                    return callback('ต้องมีความยาวระหว่าง 6-20 ตัวอักษร')
                }
                return callback()
            }
            return callback()
        },
        passwordValidator (rule, value, callback) {
            if (value) {
                if (!value.match(this.validPasswordCharacters)) {
                    return callback('ตัวอักษรที่ใช้ต้องเป็น (a-zA-Z0-9_.!@#$%^&*) เท่านั้น')
                }
                if (value.length < 8) {
                    return callback('ต้องมีความมากกว่า 8 ตัวอักษร')
                }
                return callback()
            }
            return callback()
        },
    },
}
</script>
