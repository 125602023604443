import MixinUpdateFormModal from '@/mixins/MixinUpdateFormModal'

export default {
    mixins: [MixinUpdateFormModal],
    props: {
        dataSource: {
            type: Array,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        pagination: {
            type: Object,
            default: undefined,
        },
        disableClickToEditRow: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        handleTableChange (pagination, filters, sorter) {
            this.$emit('change', pagination, filters, sorter)
        },
        onConfirmDelete (record) {
            this.$emit('delete', record.id)
        },
        createClickToEditRow (record) {
            if (this.disableClickToEditRow) return {}
            return {
                on: {
                    click: () => {
                        this.openUpdateForm(record.id)
                    },
                },
            }
        },
    },
}
