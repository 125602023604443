var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-button",
        {
          staticStyle: { position: "absolute", left: "0" },
          attrs: { type: "primary", icon: "reload", loading: _vm.loading },
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.onClick.apply(null, arguments)
            }
          }
        },
        [_vm._v("\n        รีโหลด\n    ")]
      ),
      _vm._t("default", function() {
        return [_c("a", [_vm._v("Prev")])]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }