var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "v-image" },
    [
      !_vm.loading && _vm.src
        ? [
            _c("img", {
              staticClass: "v-image__img",
              style: _vm.style,
              attrs: { src: _vm.src, alt: _vm.alt }
            })
          ]
        : _c(
            "div",
            { staticClass: "v-image__img", style: _vm.style },
            [_vm._t("default")],
            2
          )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }