<template>
    <div class="v-form-item-cover-photo">
        <VFormItemPhotoVue
            :form="form"
            :data="data"
            :width="ImageSize.w"
            :height="ImageSize.h"
            label="รูปภาพปก (Cover Photo) "
            prop-name="coverPhotoUrl" />
    </div>
</template>
<script>
import FormItems from '@/mixins/FormItems'
import VFormItemPhotoVue from '@/components/VFormItemPhoto .vue'
import { EntityImageSize } from '@/utils/enum'

export default {
    components: {
        VFormItemPhotoVue,
    },
    mixins: [FormItems],
    data () {
        return {
            ImageSize: EntityImageSize.PRODUCT_COVER,
        }
    },
    methods: {
        mapDataToFormItems () {
            return {}
        },
    },
}
</script>
<style lang="stylus">
$logo_width = 128px
$logo_high = 128px

.v-form-item-cover-photo__uploader-text
    font-size: 16px
    margin-top: 8px
    font-weight: bold

.v-form-item-cover-photo__uploader-hint
    font-size: 14px

.v-form-item-cover-photo__uploader
    width: 100%

    .ant-upload-select-picture-card
        width: 100%
        color: #999

        i
            font-size: 48px
            margin-bottom: 16px
            color: $primary-color

// .v-form-item-cover-photo__uploader-preview-img-container
//     position: relative
//     width: 100%
//     padding-bottom: 56.25%

.v-form-item-cover-photo__uploader-preview-img
    position: absolute
    width: 100%
    height: 100%
    background-size: cover
    background-repeat: no-repeat

.v-form-item-cover-photo__placeholder
    display flex
    align-items center
    justify-content: center;
    height 100%
</style>
