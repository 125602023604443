<template>
    <div class="v-image">
        <template v-if="!loading && src">
            <img
                class="v-image__img"
                :src="src"
                :style="style"
                :alt="alt">
        </template>
        <div
            v-else
            class="v-image__img"
            :style="style">
            <slot />
        </div>
    </div>
</template>
<script>
export default {
    props: {
        maxWidth: {
            type: String,
            default: null,
        },
        maxHeight: {
            type: String,
            default: null,
        },
        ratio: {
            type: Number,
            required: true,
        },
        src: {
            type: String,
            default: null,
        },
        alt: {
            type: String,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    // data () {
    //     return {
    //         defaultRatio: 16 / 9,
    //     }
    // },
    computed: {
        style () {
            const { ratio, maxWidth, maxHeight } = this
            return { aspectRatio: ratio, maxWidth, maxHeight }
        },
        // paddingBottom () {
        //     return `${(1 / (this.ratio || this.defaultRatio)) * 100}%`
        // },
    },
}
</script>
<style lang="stylus">
.v-image__container
    position: relative
    width: 100%
.v-image__img
    object-fit: cover
    width: 100%
</style>
