export default {
    data () {
        return {
            visibleUpdateForm: false,
            selectedEntityId: null,
        }
    },
    methods: {
        openUpdateForm (id) {
            this.visibleUpdateForm = true
            this.selectedEntityId = id
        },
        closeUpdateForm () {
            this.visibleUpdateForm = false
        },
        clearSelectedEntityId () {
            this.selectedEntityId = null
        },
    },
}
