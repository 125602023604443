import MixinEntitiesLazyLoad from '@/mixins/MixinEntitiesLazyLoad'

export default {
    mixins: [MixinEntitiesLazyLoad],
    data () {
        return {
            page: 1,
            size: 16,
            total: this.size,
            isResetOnLoadMore: true,
            firstPageLoading: false,
        }
    },
    computed: {
        pagination () {
            return {
                current: this.page,
                pageSize: this.size,
                total: this.total ? this.total : this.size,
                position: 'both',
            }
        },
        isLastPage () {
            return this.entityIds.length >= this.total
        },
    },
    watch: {
        page () {
            this.onFetchEntities()
        },
    },
    methods: {
        async fetchEntityPagination () {
            if (process.env.NODE_ENV !== 'production') {
                // eslint-disable-next-line no-console
                console.error(('[mixins: EntitiesPagination] Please overide fetchEntityPagination() method.'))
            }
        },
        async fetchEntityWithLazyLoad () {
            return this.fetchEntityPagination()
        },
        onCreateEntityPagination (entity) {
            return this.onCreateEntityWithLazyLoad(entity)
        },
        async onDeleteEntityPagination (entityId) {
            await this.onDeleteEntityWithLazyLoad(entityId)
        },
        onLoadMore () {
            this.page += 1
            // we auto fetch on page change so we don't need to call fetch method here
            // you can see it on watch prop
        },
        onSuccessFetchEntitiesWithPagination (res) {
            if (this.page === 1) {
                this.$nextTick(() => {
                    this.firstPageLoading = true
                })
            }
            const { total } = res.data.pageInfo
            this.total = total
            this.onSuccessFetchEntitiesWithLazyLoad(res)
            if (this.page === 1) {
                this.$nextTick(() => {
                    this.firstPageLoading = false
                })
            }
        },
        onSuccessFetchEntities (res) {
            this.onSuccessFetchEntitiesWithPagination(res)
        },
        handleTableChange (pagination) {
            this.page = pagination.current
        },
    },
}
