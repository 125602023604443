import { AdminRole, StadiumRole } from '@/utils/enum'

export default {
    props: {
        adminMode: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        isHasPermissionInStadium (actorRole, targetRole) {
            return actorRole === StadiumRole.OWNER || actorRole > targetRole
        },
        isMeHasPermissionInStadium (me, targetRole, stadiumId) {
            const myRole = me.stadiumUserRoles.find(
                (stadiumRole) => stadiumRole.stadiumId === stadiumId,
            )
            if (!myRole) return false
            return this.isHasPermissionInStadium(myRole.role, targetRole)
        },
        isHasPermissionInAdmin (actorRole, targetRole) {
            return actorRole === AdminRole.SUPER_ADMIN || actorRole > targetRole
        },
        isMeHasPermissionInAdmin (me, targetRole) {
            const myRole = me.adminRole
            return this.isHasPermissionInAdmin(myRole, targetRole)
        },
        isMeHasPermission (me, targetRole, { adminMode, stadiumId }) {
            if (adminMode) return this.isMeHasPermissionInAdmin(me, targetRole)
            return this.isMeHasPermissionInStadium(me, targetRole, stadiumId)
        },
    },
}
