var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-form-item",
        { attrs: { "label-col": { span: 4 }, "wrapper-col": { span: 12 } } },
        [
          _c(
            "span",
            { attrs: { slot: "label" }, slot: "label" },
            [
              _vm._v(_vm._s(_vm.label) + "\n            "),
              _vm.tooltip
                ? _c(
                    "a-tooltip",
                    { attrs: { title: _vm.tooltip } },
                    [_c("a-icon", { attrs: { type: "question-circle-o" } })],
                    1
                  )
                : _vm._e(),
              _vm._v(" \n        ")
            ],
            1
          ),
          _c("a-input-number", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: [
                  "" + _vm.nameProp,
                  {
                    rules: [
                      { required: _vm.required, message: "กรุณาระบุจำนวน" },
                      { validator: _vm.validator }
                    ]
                  }
                ],
                expression:
                  "[ `${nameProp}` , { rules:\n                [\n                    { required: required,\n                      message: 'กรุณาระบุจำนวน', },\n                    { validator: validator }\n                ] } ]"
              }
            ],
            staticStyle: { marginRight: "16px" },
            attrs: {
              disabled: _vm.disabled,
              min: _vm.min,
              max: _vm.max ? _vm.max : undefined
            },
            on: { change: _vm.onChange }
          }),
          _c(
            "div",
            {
              staticClass: "stadium-boost-create-form__people-slider",
              on: {
                mouseover: function($event) {
                  _vm.tooltipVisible = true
                },
                mouseleave: function($event) {
                  _vm.tooltipVisible = false
                }
              }
            },
            [
              _c("a-slider", {
                attrs: {
                  disabled: _vm.disabled,
                  value:
                    typeof _vm.form.getFieldValue(_vm.nameProp) !== "number"
                      ? undefined
                      : _vm.form.getFieldValue(_vm.nameProp),
                  marks: _vm.marks,
                  max: _vm.maxBar,
                  min: _vm.min,
                  "tooltip-visible": _vm.tooltipVisible
                },
                on: { change: _vm.setValue, afterChange: _vm.updateMaxBar }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }