var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "ชื่อจริง"
          }
        },
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: [
                  "firstName",
                  {
                    rules: [
                      { required: true, message: "กรุณาใส่ค่าช่องนี้ด้วยค่ะ" }
                    ]
                  }
                ],
                expression:
                  "\n                [ 'firstName',\n                  { rules:\n                      [\n                          { required: true,\n                            message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', } ] } ]"
              }
            ],
            attrs: { disabled: _vm.readOnly }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "นามสกุล"
          }
        },
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: [
                  "lastName",
                  {
                    rules: [
                      {
                        required: _vm.isRequireLastName,
                        message: "กรุณาใส่ค่าช่องนี้ด้วยค่ะ"
                      }
                    ]
                  }
                ],
                expression:
                  "\n                [ 'lastName',\n                  { rules:\n                      [\n                          { required: isRequireLastName,\n                            message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', } ] } ]"
              }
            ],
            attrs: { disabled: _vm.readOnly }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "ชื่อเล่น"
          }
        },
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: ["nickName"],
                expression: "\n                [ 'nickName' ]"
              }
            ],
            attrs: { disabled: _vm.readOnly }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "เบอร์โทร"
          }
        },
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: [
                  "tel",
                  {
                    rules: [
                      { required: true, message: "กรุณาใส่ค่าช่องนี้ด้วยค่ะ" }
                    ]
                  }
                ],
                expression:
                  "\n                [ 'tel',\n                  { rules:\n                      [\n                          { required: true,\n                            message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', } ] } ]"
              }
            ],
            attrs: { disabled: _vm.readOnly }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        {
          attrs: {
            disabled: _vm.readOnly,
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "อีเมล(Email)"
          }
        },
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: [
                  "email",
                  {
                    rules: [
                      {
                        type: "email",
                        message:
                          "กรุณาใส่อีเมล ให้อยู่ในรูปแบบ xxxxxxxx@xxxx.xxx"
                      }
                    ]
                  }
                ],
                expression:
                  "[ 'email' ,\n                           {\n                               rules: [\n                                   {\n                                       type: 'email',\n                                       message:\n                                           'กรุณาใส่อีเมล ให้อยู่ในรูปแบบ xxxxxxxx@xxxx.xxx',\n                                   },\n                               ],\n                           }, ]"
              }
            ],
            attrs: { disabled: _vm.readOnly }
          })
        ],
        1
      ),
      _vm.enableAddressInput
        ? _c(
            "div",
            [
              _c(
                "a-form-item",
                {
                  attrs: {
                    "label-col": { span: 4 },
                    "wrapper-col": { span: 12 },
                    label: "ที่อยู่"
                  }
                },
                [
                  _c("a-textarea", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["address"],
                        expression: "[ 'address' ]"
                      }
                    ],
                    attrs: {
                      placeholder: "บ้านเลขที่ ถนน อำเภอ จังหวัด",
                      "auto-size": { minRows: 2, maxRows: 6 }
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    "label-col": { span: 4 },
                    "wrapper-col": { span: 12 },
                    label: "รหัสไปรษณีย์"
                  }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["postalCode"],
                        expression: "\n                    [ 'postalCode' ]"
                      }
                    ]
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.enableNoteInput
        ? _c(
            "a-form-item",
            {
              attrs: {
                "label-col": { span: 4 },
                "wrapper-col": { span: 12 },
                label: "จดบันทึก(Note)"
              }
            },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: ["note"],
                    expression: "\n                [ 'note' ]"
                  }
                ],
                attrs: { disabled: _vm.readOnly }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }