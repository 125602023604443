<template>
    <a-card
        hoverable="hoverable"
        :style="{ maxWidth }"
        :class="[ 'vcard',disabled ? 'vcard--disabled' : null ]">
        <template slot="cover">
            <a-popconfirm
                v-if="enableDelete && !disabled"
                title="ยืนยันการลบ?"
                @visibleChange="(visible)=>setVisible(visible)"
                @confirm="() => deleteCard()">
                <span
                    class="vcard__close-button"
                    :class="{ 'active': isPopupVisible() }"
                    @click.stop>
                    <a-icon type="close" />
                </span>
            </a-popconfirm>
            <div class="vcard__cover-container">
                <VImageVue
                    :src="coverPhotoUrl"
                    :ratio="coverPhotoRatio">
                    <div class="vcard__placeholder-img">
                        <a-icon type="warning" />
                        {{ placeholderLabel }}
                    </div>
                </VImageVue>
                <slot name="cover" />
                <div v-if="overlayBottomCover" class="vcard__overlay-bottom-cover">
                    <slot name="overlayBottomCover" />
                </div>
            </div>
        </template>
        <a-skeleton
            :loading="!title"
            :paragraph="{ rows: 1 }"
            active
            avatar>
            <a-card-meta :title="title">
                <a-avatar
                    v-if="avartar"
                    slot="avatar"
                    :src="avartar"
                    class="vcard__avartar"
                    size="large" />
                <div
                    v-if="description"
                    slot="description"
                    class="vcard__desc">
                    <template>
                        {{ description }}
                    </template>
                &nbsp;
                </div>
            </a-card-meta>
        </a-skeleton>
    </a-card>
</template>
<script>
import footballIcon from '@/assets/Football.png'
import FieldPlaceholder from '@/assets/FieldCardPlaceholder.png'
import { EventType } from '@/utils/enum'
import VImageVue from '@/components/VImage.vue'

export default {
    components: {
        VImageVue,
    },
    props: {
        enableDelete: {
            type: Boolean,
            default: false,
        },
        coverPlaceholder: {
            type: String,
            default: FieldPlaceholder,
        },
        coverPhotoUrl: {
            type: String,
            default: null,
        },
        coverPhotoRatio: {
            type: Number,
            default: 16 / 9,
        },
        maxWidth: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
        description: {
            type: String,
            default: null,
        },
        avartar: {
            type: String,
            default: null,
        },
        placeholderLabel: {
            type: String,
            default: 'ไม่มีรูปปกสนาม',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            popupVisible: false,
            sportIcon: footballIcon,
            EventType,
        }
    },
    computed: {
        overlayBottomCover () {
            return this.$slots.overlayBottomCover
        },
    },
    methods: {
        deleteCard () {
            this.$emit('delete')
        },
        setVisible (visible) {
            this.popupVisible = visible
        },
        isPopupVisible () {
            return this.popupVisible
        },
        getImgUrl (relativeImgUrl) {
            return relativeImgUrl || ''
        },
    },
}
</script>
<style lang="stylus">
.vcard__desc
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

.vcard
    white-space: normal
    .ant-card-cover
        position: relative
        overflow: hidden

        .vcard__cover-container
            position: relative
            width: 100%
            // padding-bottom: 56.25%
            overflow: hidden

    .ant-card-body
        border-top: 1px solid $border-color

.vcard__cover-img
    position: absolute
    width: 100%
    height: 100%
    background-size: cover
    background-repeat: no-repeat

.vcard__close-button
    font-size: 12px
    line-height: 0
    padding: $padding = 6px
    position: absolute
    right: 0px
    top: 0
    z-index: 100
    color: white
    width: auto
    background-color: rgba(0, 0, 0, 0.5)
    transition: all 0.2s
    display: inline-block

    &:hover
        color: $primary-color

.vcard__close-button.active
    color: $primary-color

.vcard:hover
    .vcard__close-button
        top: 0px

.vcard__overlay-bottom-cover
    position: absolute
    bottom: 0
    background-color: rgba(0, 0, 0, 0.6)
    width: 100%
    color: white
    padding: 8px

    span
        position: relative
        float: right

.vcard__avartar
    width: 48px
    height: 48px
    line-height: 48px

.vcard__placeholder-img
    display: flex;
    justify-content: center;
    align-items: center;
    height 100%
    font-size 16px
    font-weight bold
    background-color $placeholder-color
    i
        font-size 24px
        margin-right 8px
        color $primary-color

.vcard--disabled
    .ant-card-body
        background-color: $disable-bgcolor
        color: $disable-color
        border-top: 1px solid $border-color
    .ant-card-meta-title
        color: $disable-color
</style>
