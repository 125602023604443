<template>
    <div>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="ชื่อจริง">
            <a-input
                v-decorator="
                    [ 'firstName',
                      { rules:
                          [
                              { required: true,
                                message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', } ] } ]"
                :disabled="readOnly" />
        </a-form-item>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="นามสกุล">
            <a-input
                v-decorator="
                    [ 'lastName',
                      { rules:
                          [
                              { required: isRequireLastName,
                                message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', } ] } ]"
                :disabled="readOnly" />
        </a-form-item>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="ชื่อเล่น">
            <a-input
                v-decorator="
                    [ 'nickName' ]"
                :disabled="readOnly" />
        </a-form-item>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="เบอร์โทร">
            <a-input
                v-decorator="
                    [ 'tel',
                      { rules:
                          [
                              { required: true,
                                message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', } ] } ]"
                :disabled="readOnly" />
        </a-form-item>
        <a-form-item
            :disabled="readOnly"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="อีเมล(Email)">
            <a-input
                v-decorator="[ 'email' ,
                               {
                                   rules: [
                                       {
                                           type: 'email',
                                           message:
                                               'กรุณาใส่อีเมล ให้อยู่ในรูปแบบ xxxxxxxx@xxxx.xxx',
                                       },
                                   ],
                               }, ]"
                :disabled="readOnly" />
        </a-form-item>
        <div v-if="enableAddressInput">
            <a-form-item
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 12 }"
                label="ที่อยู่">
                <a-textarea
                    v-decorator="[ 'address' ]"
                    placeholder="บ้านเลขที่ ถนน อำเภอ จังหวัด"
                    :auto-size="{ minRows: 2, maxRows: 6 }" />
            </a-form-item>
            <a-form-item
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 12 }"
                label="รหัสไปรษณีย์">
                <a-input
                    v-decorator="
                        [ 'postalCode' ]" />
            </a-form-item>
        </div>
        <a-form-item
            v-if="enableNoteInput"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="จดบันทึก(Note)">
            <a-input
                v-decorator="
                    [ 'note' ]"
                :disabled="readOnly" />
        </a-form-item>
    </div>
</template>
<script>
import FormItems from '@/mixins/FormItems'

export default {
    mixins: [FormItems],
    props: {
        enableAddressInput: {
            type: Boolean,
            default: true,
        },
        enableNoteInput: {
            type: Boolean,
            default: false,
        },
        isRequireLastName: {
            type: Boolean,
            default: true,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        mapDataToFormItems (data) {
            let map = {
                // gender: data.gender,
                firstName: data.firstName,
                lastName: data.lastName,
                nickName: data.nickName,
                email: data.email,
                tel: data.tel,
                // dateOfBirth: data.dateOfBirth,
                // carrier: data.carrier,
            }
            const mapAddress = {
                address: data.address,
                postalCode: data.postalCode,
            }

            const note = {
                note: data.note,
            }
            if (this.enableAddressInput) {
                map = { ...map, ...mapAddress }
            }
            if (this.enableNoteInput) {
                map = { ...map, ...note }
            }
            return map
        },
    },
}
</script>
