export default {
    data () {
        return {
            visibleCreateForm: false,
            prefillData: null,
        }
    },
    methods: {
        openCreateForm () {
            this.visibleCreateForm = true
        },
        closeCreateForm () {
            this.visibleCreateForm = false
        },
    },
}
