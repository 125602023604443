<template>
    <div>
        <!-- <div style="margin-bottom: 16px">
            <a-button
                type="primary"
                :disabled="!hasSelected"
                :loading="loading"
                @click="start">
                Reload
            </a-button>
            <span style="margin-left: 8px">
                <template v-if="hasSelected">
                    {{ `Selected ${selectedRowKeys.length} items` }}
                </template>
            </span>
        </div> -->
        <a-table
            class="vtable"
            :row-key="rowKey"
            :data-source="dataSource"
            :columns="columns"
            :loading="loading"
            table-layout="fixed"
            :scroll="enableScroll ?
                { x: 'calc(' + fixedWidth + 'px + ' + flexibleWidth + ')' ,y: '100%' } : {}"
            size="small"
            :custom-row="customRow"
            :pagination="pagination ? { ...pagination,'item-render': itemRender } : pagination"
            :row-class-name="rowClassName"
            @refresh-table="()=>this.$emit('refresh-table')"
            @change="handleTableChange">

            <template
                v-slot:text="text">
                {{ text || '-' }}
            </template>
            <template
                v-slot:amount="text">
                {{ text == null ? '-' : (text == -1 ? "ไม่จำกัด" : text ) }}
            </template>
            <template
                v-slot:currency="text">
                {{ text == null ? '-' : text | formatCurrency }}
            </template>
            <template
                v-slot:boolean="text">
                <a-icon v-if="text" type="check" />
                <a-icon v-else type="minus" />
            </template>
            <template
                slot="date"
                slot-scope="text">
                {{ text | formatDate }}
                    &nbsp;&nbsp;
                <a-icon
                    v-if="text"
                    type="calendar" />
            </template>
            <template
                slot="dateTime"
                slot-scope="text">
                <div style="text-align: right">
                    {{ text | formatDate }}
                    <br>
                    {{ text | formatTime }}
                    <a-icon v-if="text" type="clock-circle" />
                </div>

            </template>
            <template
                slot="icon"
                slot-scope="text">
                <VIcon :relative-path="text" />
            </template>
            <template v-for="(index, name) in $scopedSlots" v-slot:[name]="text,record ">
                <slot
                    :name="name"
                    v-bind="{ text,record }" />
            </template>
        </a-table>
    </div>
</template>
<script>
import { getEnumByValue } from '@/utils/enum'
import VIcon from '@/components/VIcon.vue'
import PrevButton from '@/components/PrevButton.vue'

export default {
    components: {
        VIcon,
    },
    props: {
        dataSource: {
            type: Array,
            required: true,
        },
        columns: {
            type: Array,
            required: true,
        },
        rowKey: {
            type: String,
            default: 'id',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        customRow: {
            type: Function,
            default: null,
        },
        rowClassName: {
            type: Function,
            // eslint-disable-next-line no-unused-vars
            default: (_record, _index, _indent) => {},
        },
        enableScroll: {
            type: Boolean,
            default: true,
        },
        pagination: {
            type: Object,
            default: () => ({
                hideOnSinglePage: true,
            }),
        },
        flexibleWidth: {
            type: String,
            default: '30%',
        },
        onRefresh: {
            type: Function,
            default: null,
        },
    },
    computed: {
        fixedWidth () {
            return this.columns
                .filter((c) => c.width)
                .filter((c) => typeof c.width === 'number')
                .reduce((prev, current) => prev + current.width, 0)
        },
    },
    methods: {
        handleTableChange (pagination, filters, sorter) {
            this.$emit('change', pagination, filters, sorter)
        },
        getEnumByValue (value) {
            return getEnumByValue(value)
        },
        itemRender (current, type, originalElement) {
            if (type === 'prev') {
                if (this.onRefresh == null) return originalElement
                return <PrevButton
                    onClick={() => this.onRefresh()}
                    loading={this.loading}>{() => originalElement}
                </PrevButton>
            }
            if (type === 'next') {
                return originalElement
            }
            return originalElement
        },
    },
}
</script>
<style lang="stylus">
.vtable
    clear both
    .ant-table-body
        overflow auto !important
</style>
