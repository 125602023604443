<template>
    <a-list
        :loading="loading"
        :grid="grid"
        :data-source="dataSource">
        <a-list-item slot="renderItem" slot-scope="item">
            <slot :item="item" />
        </a-list-item>
    </a-list>
</template>
<script>
export default {
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        dataSource: {
            type: Array,
            required: true,
        },
        grid: {
            type: Object,
            default () {
                return {
                    gutter: 16, xs: 1, sm: 1, md: 2, lg: 3, xl: 4, xxl: 4,
                }
            },
        },
    },
}
</script>
