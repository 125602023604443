export default {
    methods: {
        getVariantPricingKeysPropName (indexVariant) {
            return `variantPricingKeys[${indexVariant}]`
        },
        getVariantPricingKeys (indexVariant) {
            const propName = this.getVariantPricingKeysPropName(indexVariant)
            const value = this.form.getFieldValue(propName)
            if (value === null || value === undefined) {
                this.form.getFieldDecorator(propName, {
                    initialValue: [],
                    preserve: true,
                })
            }
            return this.form.getFieldValue(propName)
        },
        getPricingKeysOfVariant (indexVariant) {
            return this.getVariantPricingKeys(indexVariant)
                ? this.getVariantPricingKeys(indexVariant) : []
        },
        getPricingMethodKeyFromEnableOnly (index, paymentMethod) {
            const key = this.getPricingKeysOfVariant(index).findIndex(
                (pkey) => pkey === paymentMethod,
            )
            if (key < 0) return null
            return key
        },
        removeVariantPricingKey (indexVariant, value) {
            const { form } = this
            // can use data-binding to get
            const keys = this.getPricingKeysOfVariant(indexVariant)
            const nextKeys = keys.filter((key) => key !== value)

            // can use data-binding to set
            // important! notify form to detect changes
            form.setFieldsValue({
                [this.getVariantPricingKeysPropName(indexVariant)]: nextKeys,
            })
        },
        addVariantPricingKey (indexVariant, value) {
            const { form } = this
            // can use data-binding to get
            const keys = this.getPricingKeysOfVariant(indexVariant)
            const nextKeys = keys.concat(value)

            // can use data-binding to set
            // important! notify form to detect changes
            form.setFieldsValue({
                [this.getVariantPricingKeysPropName(indexVariant)]: nextKeys,
            })
        },
    },
}
