var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.relativePath
      ? _c("img", {
          staticClass: "vIcon",
          attrs: { alt: "icon", src: _vm.fullPath }
        })
      : _c(
          "div",
          [
            _c("a-icon", {
              attrs: { type: "plus", "view-box": "0 0 1024 1024" }
            })
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }