var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-tag", { staticClass: "tag-new", attrs: { color: "#f50" } }, [
    _vm._v("\n    ใหม่\n")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }