<template>
    <div class="v-form-item-cover-photo">
        <a-form-item>
            <span slot="label">{{ label }} &nbsp;
                <br>
                <a-tooltip>
                    <template slot="title">
                        <!--  eslint-disable-next-line vue/no-v-html -->
                        <p v-html="tooltip" />
                    </template>
                    <a-icon type="question-circle-o" />
                </a-tooltip>&nbsp;
            </span>
            <a-upload
                class="v-form-item-cover-photo__uploader"
                :class="{ 'v-form-item-cover-photo--circle': isCircle }"
                :style="style"
                :name="propName"
                :before-upload="beforeUploadPhoto"
                list-type="picture-card"
                :show-upload-list="false">
                <VImage
                    :ratio="ratio"
                    :src="previewImgUrl"
                    :loading="loading">
                    <div class="v-form-item-cover-photo__placeholder">
                        <slot name="placeholder" :loading="loading">
                            <div>
                                <div>
                                    <p>
                                        <a-icon :type="loading ? 'loading' : 'inbox'" />
                                    </p>
                                    <p class="v-form-item-cover-photo__uploader-text">
                                        คลิกเพื่อเลือกรูปภาพ หรือ ทำการลากรูปภาพมาใส่ช่องนี้ได้เลย
                                    </p>
                                </div>
                                <div>
                                    <!--  eslint-disable-next-line vue/no-v-html -->
                                    <p class="v-form-item-cover-photo__uploader-hint" v-html="tooltip" />
                                </div>
                            </div>
                        </slot>
                    </div>
                </VImage>
            </a-upload>
        </a-form-item>
    </div>
</template>
<script>
import VImage from '@/components/VImage.vue'
import FormItems from '@/mixins/FormItems'
import FormItemPhoto from '@/mixins/FormItemPhoto'

export default {
    components: {
        VImage,
    },
    mixins: [FormItems, FormItemPhoto],
    props: {
        label: {
            type: String,
            required: true,
        },
        propName: {
            type: String,
            required: true,
        },
        isCircle: {
            type: Boolean,
            default: false,
        },
        isDisableDefaultMaxWidth: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            paddingPhotoForm: 18,
        }
    },
    computed: {
        tooltip () {
            return `
    แนะนำอัตราส่วนของรูปเป็น ${ this.ratioString }  <br>
    ความระเอียดไม่ต่ำกว่า ${this.resolutionString} <br>
    นามสกุลไฟล์ที่รับได้คือ .jpg, .jpeg, .png, .svg เท่านั้น
    `
        },
        style () {
            const {
                height, width, ratio,
                paddingPhotoForm,
                isExpanded, isDisableDefaultMaxWidth,
            } = this
            if (isExpanded) return { width: '100%' }
            let maxWidth = width
            let maxHeight = height
            // 464 is for display default placeholder properly (not overflow the box)
            // if you pass custom placeholder from slot, you can set isShinked=true
            if (width < 464 && !isDisableDefaultMaxWidth) {
                maxWidth = 464
                maxHeight = 464 * (1 / ratio)
            }
            return {
                maxWidth: `${maxWidth + paddingPhotoForm }px`,
                maxHeight: `${maxHeight + paddingPhotoForm }px`,
            }
        },
    },
}
</script>
<style lang="stylus">

.v-form-item-cover-photo__uploader-text
    font-size: 16px
    margin-top: 8px
    font-weight: bold

.v-form-item-cover-photo__uploader-hint
    font-size: 14px

.v-form-item-cover-photo__uploader
    width: 100%
    .ant-upload-select-picture-card
        width: 100%
        color: #999
        i
            font-size: 48px
            margin-bottom: 16px
            color: $primary-color

.v-form-item-cover-photo__uploader.v-form-item-cover-photo--circle
    > .ant-upload,> .ant-upload img
        border-radius: 100%;

// .v-form-item-cover-photo__uploader-preview-img-container
//     position: relative
//     width: 100%
//     padding-bottom: 56.25%

.v-form-item-cover-photo__uploader-preview-img
    position: absolute
    width: 100%
    height: 100%
    background-size: cover
    background-repeat: no-repeat

.v-form-item-cover-photo__placeholder
    display flex
    align-items center
    justify-content: center;
    height 100%
</style>
