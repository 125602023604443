<template>
    <div>
        <a-form
            v-if="!loading"
            v-disable-update="disabled"
            :form="form"
            :label-col="{ xxl: { span: 4 },md: { span: 4 } }"
            :wrapper-col="{ xxl: { span: 12 },md: { span: 18 } }"
            :layout="layout"
            @submit="handleSubmit">
            <slot :form="form" :data="data" />
            <a-form-item :wrapper-col="{ xxl: { span: 12,offset: 4 },lg: { span: 18,offset: 4 } }">
                <a-button
                    class="vform__button"
                    :disabled="form._data.disabled"
                    type="primary"
                    html-type="submit"
                    :loading="isProcessing">
                    บันทึก
                </a-button>
                <a-popconfirm
                    v-if="onDeleteAsync"
                    title="ยืนยันการลบ?"
                    @confirm="() => onDeleteAsync()">
                    <span
                        @click.stop>
                        <a-button
                            class="vform__button"
                            :disabled="form._data.disabled"
                            type="danger"
                            :loading="isProcessing"
                            ghost>
                            <a-icon type="delete" />
                            ลบ
                        </a-button>
                    </span>
                </a-popconfirm>
            </a-form-item>
        </a-form>
        <a-skeleton
            :loading="loading"
            :paragraph="{ rows: 6 }"
            active />
    </div>
</template>
<script>
import Form from '@/mixins/Form'

export default {
    directives: {
        disableUpdate: {
            // When all the children of the parent component have been updated
            componentUpdated (el, binding) {
                if (binding && binding.value === false) return
                const tags = ['input', 'button', 'textarea', 'select']
                tags.forEach((tagName) => {
                    const nodes = el.getElementsByTagName(tagName)
                    const nodeList = [...nodes]
                    nodeList.forEach((element) => {
                        const e = element
                        e.disabled = true
                        e.tabIndex = -1
                    })
                })
            },
        },
    },
    mixins: [Form],
    props: {
        onSubmitAsync: {
            type: Function,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        onDeleteAsync: {
            type: Function,
            default: null,
        },
    },
    data () {
        return {
            layout: 'horizontal',
        }
    },
    async created () {
        window.addEventListener('resize', this.handleResize)
    },
    destroyed () {
        window.removeEventListener('resize', this.handleResize)
    },
    methods: {
        handleResize () {
            if (window.innerWidth < 992) {
                this.layout = 'vertical'
            } else {
                this.layout = 'horizontal'
            }
        },
        async submit (values) {
            return this.onSubmitAsync(values)
        },
    },
}
</script>
<style lang="stylus">
.vform__button
    margin-right: 8px
</style>
