<template>
    <div>
        <a-button
            type="primary"
            icon="reload"
            style="position: absolute;left:0"
            :loading="loading"
            @click.stop="onClick">
            รีโหลด
        </a-button>
        <slot>
            <a>Prev</a>
        </slot>
    </div>
</template>
<script>
export default {
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        // eslint-disable-next-line no-unused-vars
        onClick () {
            this.$emit('click')
        },
    },
}
</script>
