import moment from 'moment'
import FormItems from '@/mixins/FormItems'
import { isSameDate, range } from '@/utils/utils'

export default {
    mixins: [FormItems],
    props: {
        labelStartTime: {
            type: String,
            default: 'เวลาเริ่ม',
        },
        labelEndTime: {
            type: String,
            default: 'เวลาจบ',
        },
        namePropStartTime: {
            type: String,
            default: 'startTime',
        },
        namePropEndTime: {
            type: String,
            default: 'endTime',
        },
        required: {
            type: Boolean,
            defaule: false,
        },
        isPreventStartBeforeEnd: {
            type: Boolean,
            defaule: false,
        },
        validatorEndTime: {
            type: Function,
            default: null,
        },
        minuteStep: {
            type: Number,
            default: 15,
        },
        shink: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            startTimePickerOpen: false,
            endTimePickerOpen: false,
            endTimePickerDirty: false,
            moment,
            startValue: null,
            endValue: null,
        }
    },
    methods: {
        isSameDate,
        range,
        handleClose () {
            this.startTimePickerOpen = false
            this.endTimePickerOpen = false
        },
        disabledEndDate (endValue) {
            const { startValue } = this
            if (!endValue || !startValue) {
                return false
            }
            return startValue.valueOf() > endValue.valueOf()
        },
        disabledEndTime (endValue) {
            const { startValue } = this
            if (!endValue || !startValue) {
                return {}
            }
            if (isSameDate(startValue, endValue)) {
                return {
                    disabledHours: () => this.range(0, startValue.get('hour') - 1),
                    disabledMinutes: () => (startValue.get('hour') === endValue.get('hour') ? this.range(0, startValue.get('minute')) : []),
                }
            }
            if (startValue > endValue) {
                return {
                    disabledHour: () => this.range(0, 60),
                    disabledMinutes: () => this.range(0, 60),
                }
            }
            return {}
        },
        validateStartTimeIsBeforeEndTime (rule, value, callback) {
            if (value && !this.form.getFieldValue(this.namePropStartTime).isBefore(value)) {
                callback(` ${this.labelEndTime} ไม่สามารถมาก่อน ${this.labelStartTime} ได้`)
            } else {
                callback()
            }
        },
        validateEndTimeField (rule, value, callback) {
            if (value) {
                if (this.isSameTime(value, this.form.getFieldValue(this.namePropStartTime))) {
                    return callback('เวลาจบ ต้องไม่เท่ากับ เวลาเริ่ม')
                }
            }
            return callback()
        },
        isSameTime (start, end) {
            return start.format('HH:mm:ss') === end.format('HH:mm:ss')
        },
        isSetStartTime () {
            return this.form.getFieldValue(this.namePropStartTime)
        },
        getHourBeforeStartTime () {
            const startHour = this.getStartTimeHour()
            const before = []
            for (let i = 0; i < startHour; i += 1) {
                before.push(i)
            }
            return before
        },
        getStartTimeHour () {
            const fieldValue = this.form.getFieldValue(this.namePropStartTime)
            return fieldValue ? fieldValue.get('H') : 0
        },
        getStartTimeMinute () {
            const fieldValue = this.form.getFieldValue(this.namePropStartTime)
            return fieldValue ? fieldValue.get('m') : 0
        },
        getMinuteBeforeStartTime (selectedHour) {
            const startHour = this.getStartTimeHour()
            if (startHour === selectedHour) {
                const startMinute = this.getStartTimeMinute()
                const before = []
                for (let i = 0; i <= startMinute; i += 15) {
                    before.push(i)
                }
                return before
            }
            return []
        },
        triggerValidateEndTimeField (rule, value, callback) {
            if (value && this.endTimePickerDirty) {
                this.form.validateFields([this.namePropEndTime], {
                    force: true,
                },
                () => {
                    // Put empty function to prevent echo errors.
                })
            }
            return callback()
        },
        mapDataToFormItems (data) {
            this.startValue = data[this.namePropStartTime]
            this.endValue = data[this.namePropEndTime]
            return {
                [this.namePropStartTime]: data[this.namePropStartTime],
                [this.namePropEndTime]: data[this.namePropEndTime],
            }
        },
        handleStartOpenChange (open) {
            this.startTimePickerOpen = open
            const close = !open
            if (close) {
                this.endTimePickerOpen = true
            }
        },
        onConfirmSelectionStart () {
            this.startTimePickerOpen = false
            if (this.startValue) {
                this.endTimePickerOpen = true
            }
        },
        onConfirmSelectionEnd () {
            this.handleClose()
        },
        handleEndChange (value) {
            this.endValue = value
            this.$emit('endDateTimeChange', value)
            this.endTimePickerDirty = true
        },
        handleStartChange (value) {
            this.startValue = value
            if (this.isPreventStartBeforeEnd) {
                if (!this.endValue || this.endValue.isBefore(this.startValue)) {
                    this.form.setFieldsValue({
                        [this.namePropEndTime]: this.startValue,
                    })
                    this.endValue = value
                }
            }
            this.$emit('startDateTimeChange', this.startValue)
        },
        handleEndOpenChange (open) {
            this.endTimePickerOpen = open
        },
        setEndToMidNight () {
            this.endValue = moment('23:59:59', 'HH:mm:ss')
            this.form.setFieldsValue({
                [this.namePropEndTime]: this.endValue,
            })
            this.onConfirmSelectionEnd()
        },
        setStartToMidNight () {
            this.startValue = moment('00:00:00', 'HH:mm:ss')
            this.form.setFieldsValue({
                [this.namePropStartTime]: this.startValue,
            })
            this.onConfirmSelectionStart()
        },
    },
}
