var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "v-form-item-cover-photo" },
    [
      _c(
        "a-form-item",
        [
          _c(
            "span",
            { attrs: { slot: "label" }, slot: "label" },
            [
              _vm._v(_vm._s(_vm.label) + "  \n            "),
              _c("br"),
              _c(
                "a-tooltip",
                [
                  _c("template", { slot: "title" }, [
                    _c("p", { domProps: { innerHTML: _vm._s(_vm.tooltip) } })
                  ]),
                  _c("a-icon", { attrs: { type: "question-circle-o" } })
                ],
                2
              ),
              _vm._v(" \n        ")
            ],
            1
          ),
          _c(
            "a-upload",
            {
              staticClass: "v-form-item-cover-photo__uploader",
              class: { "v-form-item-cover-photo--circle": _vm.isCircle },
              style: _vm.style,
              attrs: {
                name: _vm.propName,
                "before-upload": _vm.beforeUploadPhoto,
                "list-type": "picture-card",
                "show-upload-list": false
              }
            },
            [
              _c(
                "VImage",
                {
                  attrs: {
                    ratio: _vm.ratio,
                    src: _vm.previewImgUrl,
                    loading: _vm.loading
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "v-form-item-cover-photo__placeholder" },
                    [
                      _vm._t(
                        "placeholder",
                        function() {
                          return [
                            _c("div", [
                              _c("div", [
                                _c(
                                  "p",
                                  [
                                    _c("a-icon", {
                                      attrs: {
                                        type: _vm.loading ? "loading" : "inbox"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "v-form-item-cover-photo__uploader-text"
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    คลิกเพื่อเลือกรูปภาพ หรือ ทำการลากรูปภาพมาใส่ช่องนี้ได้เลย\n                                "
                                    )
                                  ]
                                )
                              ]),
                              _c("div", [
                                _c("p", {
                                  staticClass:
                                    "v-form-item-cover-photo__uploader-hint",
                                  domProps: { innerHTML: _vm._s(_vm.tooltip) }
                                })
                              ])
                            ])
                          ]
                        },
                        { loading: _vm.loading }
                      )
                    ],
                    2
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }