var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.loading
        ? _c(
            "a-form",
            {
              directives: [
                {
                  name: "disable-update",
                  rawName: "v-disable-update",
                  value: _vm.disabled,
                  expression: "disabled"
                }
              ],
              attrs: {
                form: _vm.form,
                "label-col": { xxl: { span: 4 }, md: { span: 4 } },
                "wrapper-col": { xxl: { span: 12 }, md: { span: 18 } },
                layout: _vm.layout
              },
              on: { submit: _vm.handleSubmit }
            },
            [
              _vm._t("default", null, { form: _vm.form, data: _vm.data }),
              _c(
                "a-form-item",
                {
                  attrs: {
                    "wrapper-col": {
                      xxl: { span: 12, offset: 4 },
                      lg: { span: 18, offset: 4 }
                    }
                  }
                },
                [
                  _c(
                    "a-button",
                    {
                      staticClass: "vform__button",
                      attrs: {
                        disabled: _vm.form._data.disabled,
                        type: "primary",
                        "html-type": "submit",
                        loading: _vm.isProcessing
                      }
                    },
                    [_vm._v("\n                บันทึก\n            ")]
                  ),
                  _vm.onDeleteAsync
                    ? _c(
                        "a-popconfirm",
                        {
                          attrs: { title: "ยืนยันการลบ?" },
                          on: {
                            confirm: function() {
                              return _vm.onDeleteAsync()
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                }
                              }
                            },
                            [
                              _c(
                                "a-button",
                                {
                                  staticClass: "vform__button",
                                  attrs: {
                                    disabled: _vm.form._data.disabled,
                                    type: "danger",
                                    loading: _vm.isProcessing,
                                    ghost: ""
                                  }
                                },
                                [
                                  _c("a-icon", { attrs: { type: "delete" } }),
                                  _vm._v(
                                    "\n                        ลบ\n                    "
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            2
          )
        : _vm._e(),
      _c("a-skeleton", {
        attrs: { loading: _vm.loading, paragraph: { rows: 6 }, active: "" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }