<template>
    <div>
        <a-form-item
            :label="shink ? null : labelStartTime"
            :style="shink ? { display: 'inline-block',marginBottom: '0' } : {}"
            has-feedback="has-feedback"
            :validate-status="null"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }">
            <a-time-picker
                v-decorator="[ namePropStartTime ,
                               { rules:
                                   [
                                       { required: required,
                                         message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', }
                                       ,{ validator: triggerValidateEndTimeField } ] } ]"
                :minute-step="minuteStep"
                format="HH:mm"
                size="large"
                :open.sync="startTimePickerOpen"
                :default-open-value="moment('00:00:00', 'HH:mm:ss')"
                @change="handleStartChange"
                @openChange="handleStartOpenChange">
                <a-button
                    slot="addon"
                    size="small"
                    type="primary"
                    @click="onConfirmSelectionStart()">
                    เลือก
                </a-button>
                <a-button
                    slot="addon"
                    size="small"
                    @click="setStartToMidNight">
                    00:00
                </a-button>
            </a-time-picker>
        </a-form-item>
        <b v-if="shink">
            -
        </b>
        <a-form-item
            :label="shink ? null : labelEndTime"
            :style="shink ? { display: 'inline-block',marginBottom: '0' } : {}"
            has-feedback="has-feedback"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }">
            <a-time-picker
                v-decorator="
                    [ namePropEndTime,
                      { rules:
                          [
                              { required: required || isSetStartTime(),
                                message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', },{
                                    validator: validatorEndTime ? validatorEndTime : validateEndTimeField
                                } ] } ]"
                :minute-step="minuteStep"
                format="HH:mm"
                size="large"
                :open.sync="endTimePickerOpen"
                :default-open-value="moment('00:00:00', 'HH:mm:ss')"
                :disabled="isPreventStartBeforeEnd
                    ? !isSetStartTime() : false"
                :disabled-hours="isPreventStartBeforeEnd
                    ? ()=>getHourBeforeStartTime() : ()=>[]"
                :disabled-minutes="isPreventStartBeforeEnd
                    ? (selectedHour)=>getMinuteBeforeStartTime(selectedHour) : ()=>[]"
                has-feedback="has-feedback"
                @change="handleEndChange"
                @blur="endTimePickerDirty = true">
                <a-button
                    slot="addon"
                    size="small"
                    type="primary"
                    @click="onConfirmSelectionEnd">
                    เลือก
                </a-button>
                <a-button
                    slot="addon"
                    size="small"
                    @click="setEndToMidNight">
                    23.59
                </a-button>
            </a-time-picker>
        </a-form-item>
        <slot />
        <!-- <a-form-item
            :label="labelEndTime"
            has-feedback="has-feedback"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            >
            <a-time-picker
                v-decorator="
                    [ namePropEndTime,
                      { rules:
                          [
                              { required: required,
                                message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', },{
                                    validator: validatorEndTime ? validatorEndTime : validateEndTimeField
                                } ] } ]"
                :minute-step="minuteStep"
                format="HH:mm"
                size="large"
                :open.sync="endTimePickerOpen"
                :default-open-value="moment('00:00:00', 'HH:mm:ss')"
                :disabled="isPreventStartBeforeEnd
                    ? !isSetStartTime() : false"
                :disabled-hours="isPreventStartBeforeEnd
                    ? ()=>getHourBeforeStartTime() : ()=>[]"
                :disabled-minutes="isPreventStartBeforeEnd
                    ? (selectedHour)=>getMinuteBeforeStartTime(selectedHour) : ()=>[]"
                has-feedback="has-feedback"
                @change="handleEndChange"
                @blur="endTimePickerDirty = true">
                <a-button
                    slot="addon"
                    size="small"
                    type="primary"
                    @click="handleClose">
                    เลือก
                </a-button>
            </a-time-picker>
        </a-form-item> -->
    </div>
</template>

<script>
import MixinFormItemRangeTime from '@/mixins/MixinFormItemRangeTime'

export default {
    mixins: [MixinFormItemRangeTime],
}
</script>
