<template>
    <div>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }">
            <span slot="label">{{ label }}
                <a-tooltip
                    v-if="tooltip"
                    :title="tooltip">
                    <a-icon type="question-circle-o" />
                </a-tooltip>&nbsp;
            </span>
            <a-input-number
                v-decorator="[ `${nameProp}` , { rules:
                    [
                        { required: required,
                          message: 'กรุณาระบุจำนวน', },
                        { validator: validator }
                    ] } ]"
                :disabled="disabled"
                :min="min"
                :max="max ? max : undefined "
                style="marginRight: 16px"
                @change="onChange" />
            <div
                class="stadium-boost-create-form__people-slider"
                @mouseover="tooltipVisible = true"
                @mouseleave="tooltipVisible = false">
                <a-slider
                    :disabled="disabled"
                    :value="typeof form.getFieldValue(nameProp) !== 'number'
                        ? undefined : form.getFieldValue(nameProp)"
                    :marks="marks"
                    :max="maxBar"
                    :min="min"
                    :tooltip-visible="tooltipVisible"
                    @change="setValue"
                    @afterChange="updateMaxBar" />
            </div>
        </a-form-item>
    </div>
</template>

<script>
import FormItems from '@/mixins/FormItems'

export default {
    mixins: [FormItems],
    props: {
        label: {
            type: String,
            required: true,
        },
        tooltip: {
            type: String,
            default: null,
        },
        nameProp: {
            type: String,
            required: true,
        },
        required: {
            type: Boolean,
            default: false,
        },
        validator: {
            type: Function,
            default: (rule, value, callback) => callback(),
        },
        min: {
            type: Number,
            default: null,
        },
        max: {
            type: Number,
            default: null,
        },
    },
    data () {
        return {
            maxBar: this.max ? this.max : 40,
            tooltipVisible: false,
        }
    },
    computed: {
        marks () {
            const { maxBar } = this
            const step = Math.floor(maxBar / 4)
            const steps = [step * 1, step * 2, step * 3, step * 4]
            return steps.reduce((marks, item) => ({
                ...marks,
                [item]: `${item}`,
            }), {})
        },
    },
    methods: {
        mapDataToFormItems (data) {
            return {
                [this.nameProp]: data[this.nameProp],
            }
        },
        getMaxBar () {
            if (this.max) return this.max
            const value = this.form.getFieldValue(this.nameProp)
            return value && value > 40 ? Math.ceil(value / 40) * 40 : 40
        },
        setValue (e) {
            this.form.setFieldsValue({
                [this.nameProp]: e,
            })
        },
        updateMaxBar () {
            this.maxBar = this.getMaxBar()
        },
        onChange (e) {
            let value = e
            if (typeof value !== 'number') value = undefined
            this.setValue(value)
            this.updateMaxBar()
        },
        afterLoadDataToFormItems () {
            this.updateMaxBar()
        },
    },
}
</script>
