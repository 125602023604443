var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-descriptions",
    {
      attrs: {
        title: "รายละเอียด",
        size: "small",
        layout: "vertical",
        bordered: ""
      }
    },
    [
      _c(
        "a-descriptions-item",
        { attrs: { label: "\n                ราคาที่ลูกค้าจ่าย" } },
        [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm._f("formatCurrency")(_vm.price !== null ? _vm.price : "-")
              ) +
              "\n        "
          ),
          [
            _vm._v(
              "\n            " + _vm._s(_vm.suffixLabelPrice) + "\n        "
            )
          ]
        ],
        2
      ),
      _c("a-descriptions-item", { attrs: { label: "สนามได้รับคงเหลือ" } }, [
        _vm._v(
          "\n        " +
            _vm._s(
              _vm._f("formatCurrency")(
                _vm.price !== null ? _vm.priceWithOutFee : "-"
              )
            ) +
            "\n    "
        )
      ]),
      _c("a-descriptions-item", { attrs: { label: "ค่าธรรมเนียม" } }, [
        _vm._v(
          "\n        " +
            _vm._s(
              _vm._f("formatCurrency")(_vm.price !== null ? _vm.fee : "-")
            ) +
            "\n    "
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }