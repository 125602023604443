import FormItems from '@/mixins/FormItems'
import { gcd } from '@/utils/utils'

export default {
    mixins: [FormItems],
    props: {
        width: {
            type: Number,
            required: true,
        },
        height: {
            type: Number,
            required: true,
        },
        isExpanded: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            loading: false,
            previewImgUrl: '',
            // propName: 'coverPhoto',
        }
    },
    computed: {
        gcdOfResolution () {
            return gcd(this.width, this.height)
        },
        widthRatio () {
            return this.width / this.gcdOfResolution
        },
        heightRatio () {
            return this.height / this.gcdOfResolution
        },
        ratio () {
            return this.width / this.height
        },
        propNameFile () {
            return this.propName.substring(0, this.propName.indexOf('Url'))
        },
        ratioString () {
            return `${ this.widthRatio }:${ this.heightRatio }`
        },
        resolutionString () {
            return `${ this.width }x${ this.height }px`
        },
    },
    beforeMount () {
        this.form.getFieldDecorator(this.propNameFile, {
            initialValue: null,
            preserve: true,
        })
    },
    methods: {
        isImgFile (file) {
            const validImgFileTypes = ['image/jpeg', 'image/png', 'image/svg+xml']
            const isImg = validImgFileTypes.includes(file.type)
            if (!isImg) {
                this.$message.error('นามสกุลไฟล์ที่รับได้คือ .jpg, .jpeg, .png, .svg เท่านั้น')
            }
            return isImg
        },
        isFileTooLarge (file, limitSizeMB) {
            const isTooLarge = file.size / 1024 / 1024 > limitSizeMB
            if (isTooLarge) {
                this.$message.error(`Image must smaller than ${limitSizeMB}MB!`)
            }
            return isTooLarge
        },
        beforeUploadPhoto (file) {
            this.isImgFile(file)
            this.isFileTooLarge(file, 5)
            if (this.isImgFile(file)
            && !this.isFileTooLarge(file, 5)) {
                this.loading = true
                this.getBase64(file, (imageUrl) => {
                    this.previewImgUrl = imageUrl
                    this.loading = false
                    this.form.setFieldsValue({
                        [this.propNameFile]: file,
                    })
                })
            }
            return false
        },
        getBase64 (img, callback) {
            const reader = new FileReader()
            reader.addEventListener('load', () => callback(reader.result))
            reader.readAsDataURL(img)
        },
        mapDataToFormItems (data) {
            this.previewImgUrl = data[this.propName] || ''
            return {}
        },
    },
}
