var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-list", {
    attrs: {
      loading: _vm.loading,
      grid: _vm.grid,
      "data-source": _vm.dataSource
    },
    scopedSlots: _vm._u(
      [
        {
          key: "renderItem",
          fn: function(item) {
            return _c(
              "a-list-item",
              {},
              [_vm._t("default", null, { item: item })],
              2
            )
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }