<template>
    <div>
        <h3>ประเภทสินค้า</h3>
        <strong>ตัวบนสุด </strong>จะเป็น <strong>ตัวหลักที่ใช้ในแสดงรายละเอียด</strong>
        และจะ <strong>ถูกเลือกโดยเริ่มต้นเวลากดซื้อ</strong><br>
        <a-form-item
            v-for="(k) in form.getFieldValue('keys')"
            :key="k"
            v-bind="formItemLayout"
            :required="false">
            <a-card>
                <a-popconfirm
                    v-if="k >= 1 "
                    title="ยืนยันการลบ?"
                    @confirm="() => remove(k)">
                    <span
                        class="product-variant-form-items__remove-button"
                        @click.stop>
                        ลบ
                        <a-icon
                            v-if="k >= 1 "
                            class="dynamic-delete-button"
                            type="delete"
                            :style="{ fontSize: '16px' }" />
                    </span>
                </a-popconfirm>
                <a-form-item v-show="false">
                    <a-input
                        v-if="isUpdateForm"
                        v-decorator="
                            [ `variants[${k}][id]` ]" />
                </a-form-item>
                <a-form-item
                    :label-col="{ span: 4 }"
                    :wrapper-col="{ span: 12 }"
                    label="ชื่อประเภท">
                    <a-input
                        v-decorator="
                            [ `variants[${k}][name]`,
                              {
                                  rules:
                                      [
                                          { required: true,
                                            message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', } ] } ]"
                        :disabled="disabled" />
                </a-form-item>
                <a-form-item
                    label="จำนวนสินค้าต่อชุด"
                    help="เช่น ถ้าขายเป็นคู่ ก็คือ จำนวนสินค้าต่อชุด = 2"
                    :label-col="{ span: 4 }"
                    :wrapper-col="{ span: 12 }">
                    <a-input-number
                        v-decorator="
                            [ `variants[${k}][amountPerUnit]`,
                              {
                                  rules:
                                      [
                                          { required: true,
                                            message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', },
                                      ] } ]"
                        :disabled="disabled"
                        :min="1" />
                    <template v-if="form.getFieldValue(`variants[${k}][amountPerUnit]`)">
                        = ซื้อ 1 ชุด
                        <a-icon type="arrow-right" />
                        ได้
                        <strong>
                            {{ form.getFieldValue(`variants[${k}][amountPerUnit]`) }}
                        </strong> ชิ้น (ใช้เล่นได้
                        <strong>
                            {{ form.getFieldValue(`variants[${k}][amountPerUnit]`) }}
                        </strong>
                        คน)
                    </template>
                </a-form-item>
                <ProductVariantPricingsFormItems
                    :is-update-form="isUpdateForm"
                    :index-variant="k"
                    :form="form"
                    :data="data" />
            </a-card>
        </a-form-item>
        <a-form-item v-bind="formItemLayoutWithOutLabel">
            <a-button
                :disabled="disabled"
                type="dashed"
                style="width: 60%"
                @click="add">
                <a-icon type="plus" /> เพิ่มประเภทสินค้า
            </a-button>
        </a-form-item>
    </div>
</template>

<script>
import FormItems from '@/mixins/FormItems'
import ProductVariantPricingsFormItems from '@/components/ProductVariantPricingsFormItems.vue'
import { isEmptyArray, isObjectEmpty } from '@/utils/utils'

export default {
    components: {
        ProductVariantPricingsFormItems,
    },
    mixins: [FormItems],
    props: {
        type: {
            type: String,
            default: '',
        },
        suffixLabelPrice: {
            type: String,
            default: '',
        },
        isUpdateForm: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            nextDynamicFormId: 0,
            nextVaraintsPricingIds: { 0: 0 },
            tooltipVisible: false,
            marks: {
                1: '1',
                10: '10',
                20: '20',
                30: '30',
                40: '40',
            },
            isLoadFields: false,
            formItemLayout: {
                // labelCol: {
                //     xs: { span: 24 },
                //     sm: { span: 4 },
                // },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 24 },
                },
            },
            formItemLayoutWithOutLabel: {
                wrapperCol: {
                    xs: { span: 24, offset: 0 },
                    sm: { span: 20, offset: 4 },
                },
            },
            addPercentForPayAtStadium: 10,
        }
    },
    computed: {
        isEmptyVariants () {
            return isObjectEmpty(this.data) || isEmptyArray(this.data.variants)
        },
        isCreateForm () {
            return !this.isUpdateForm
        },
    },
    mounted () {
        this.form.getFieldDecorator('keys', { initialValue: [], preserve: true })
        // if create form, add default variant
        if (this.isCreateForm && this.isEmptyVariants) {
            this.form.setFieldsValue({
                keys: [],
            })
            this.add()
            this.form.getFieldDecorator('variants[0][name]', { initialValue: 'ธรรมดา' })
        }
    },
    methods: {
        mapDataToFormItems (data) {
            this.reset()
            if (!data.variants) return {}
            // 1. Add empty variants
            data.variants.forEach(() => this.add())
            // 2. Fill value to empty variants
            data.variants.forEach((variant, index) => {
                Object.keys(variant).forEach((key) => {
                    // ignore pricings because it will be handled by subcomponent
                    if (key !== 'pricings') {
                        this.form.getFieldDecorator(`variants[${index}][${key}]`)
                        this.form.setFieldsValue({
                            [`variants[${index}][${key}]`]: variant[key],
                        })
                    }
                })
            })
            return {}
        },
        remove (k) {
            const { form } = this
            // can use data-binding to get
            const keys = form.getFieldValue('keys')
            if (keys.length === 1) return
            // can use data-binding to set
            form.setFieldsValue({
                keys: keys.filter((key) => key !== k),
            })
        },
        add () {
            const { form } = this
            // can use data-binding to get
            const keys = form.getFieldValue('keys') ? form.getFieldValue('keys') : []
            const nextKeys = keys.concat(this.nextDynamicFormId)
            // can use data-binding to set
            // important! notify form to detect changes
            form.setFieldsValue({
                keys: nextKeys,
            })
            form.getFieldDecorator(
                `variants[${this.nextDynamicFormId}][amountPerUnit]`,
                { initialValue: 1 },
            )
            this.nextDynamicFormId += 1
        },
        reset () {
            this.form.setFieldsValue({
                keys: [],
            })
            this.nextDynamicFormId = 0
        },
    },
}
</script>
<style lang="stylus">
.product-variant-form-items__remove-button
    font-size: 12px
    line-height: 0
    padding: $padding = 6px
    position: absolute
    right: 0px
    top: 0
    z-index: 100
    width: auto
    display: inline-block
    cursor: pointer
    &:hover
        color: $primary-color

</style>
