var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        staticClass: "vtable",
        attrs: {
          "row-key": _vm.rowKey,
          "data-source": _vm.dataSource,
          columns: _vm.columns,
          loading: _vm.loading,
          "table-layout": "fixed",
          scroll: _vm.enableScroll
            ? {
                x: "calc(" + _vm.fixedWidth + "px + " + _vm.flexibleWidth + ")",
                y: "100%"
              }
            : {},
          size: "small",
          "custom-row": _vm.customRow,
          pagination: _vm.pagination
            ? Object.assign({}, _vm.pagination, {
                "item-render": _vm.itemRender
              })
            : _vm.pagination,
          "row-class-name": _vm.rowClassName
        },
        on: {
          "refresh-table": function() {
            return this$1.$emit("refresh-table")
          },
          change: _vm.handleTableChange
        },
        scopedSlots: _vm._u(
          [
            {
              key: "text",
              fn: function(text) {
                return [
                  _vm._v("\n            " + _vm._s(text || "-") + "\n        ")
                ]
              }
            },
            {
              key: "amount",
              fn: function(text) {
                return [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        text == null ? "-" : text == -1 ? "ไม่จำกัด" : text
                      ) +
                      "\n        "
                  )
                ]
              }
            },
            {
              key: "currency",
              fn: function(text) {
                return [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm._f("formatCurrency")(text == null ? "-" : text)
                      ) +
                      "\n        "
                  )
                ]
              }
            },
            {
              key: "boolean",
              fn: function(text) {
                return [
                  text
                    ? _c("a-icon", { attrs: { type: "check" } })
                    : _c("a-icon", { attrs: { type: "minus" } })
                ]
              }
            },
            {
              key: "date",
              fn: function(text) {
                return [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm._f("formatDate")(text)) +
                      "\n                  \n            "
                  ),
                  text
                    ? _c("a-icon", { attrs: { type: "calendar" } })
                    : _vm._e()
                ]
              }
            },
            {
              key: "dateTime",
              fn: function(text) {
                return [
                  _c(
                    "div",
                    { staticStyle: { "text-align": "right" } },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm._f("formatDate")(text)) +
                          "\n                "
                      ),
                      _c("br"),
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm._f("formatTime")(text)) +
                          "\n                "
                      ),
                      text
                        ? _c("a-icon", { attrs: { type: "clock-circle" } })
                        : _vm._e()
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "icon",
              fn: function(text) {
                return [_c("VIcon", { attrs: { "relative-path": text } })]
              }
            },
            _vm._l(_vm.$scopedSlots, function(index, name) {
              return {
                key: name,
                fn: function(text, record) {
                  return [
                    _vm._t(name, null, null, { text: text, record: record })
                  ]
                }
              }
            })
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }