import MixinCreateFormModal from '@/mixins/MixinCreateFormModal'
import MixinUpdateFormModal from '@/mixins/MixinUpdateFormModal'

export default {
    mixins: [MixinCreateFormModal, MixinUpdateFormModal],
    data () {
        return {
            dataSource: [],
            newEntitiesDataSource: [],
            loading: false,
            query: '',
            enableSearchRealTime: true,
        }
    },
    computed: {
        entities () {
            return this.getEntities()
        },
        newEntities () {
            return this.getNewEntities()
        },
        selectedEntity () {
            return this.entities.find((entity) => entity.id === this.selectedEntityId)
        },
        allEntities () {
            return [...this.entities, ...this.newEntities]
        },
    },
    watch: {
        entities (newValue) {
            this.updateDataSource(newValue)
            if (this.query) {
                this.onSeach(this.query)
            }
        },
        newEntities (newValue) {
            this.updateNewEntityDataSource(newValue)
            if (this.query) {
                this.onSeach(this.query)
            }
        },
    },
    created () {
        this.onCreatedComponent()
    },
    methods: {
        onCreatedComponent () {
            this.onFetchEntities()
        },
        // eslint-disable-next-line no-unused-vars
        onSuccessFetchEntities (_res) {
            this.updateDataSource(this.entities)
        },
        async onFetchEntities () {
            this.loading = true
            const res = await this.fetchEntities()
            if (res.isSuccess()) {
                this.onSuccessFetchEntities(res)
            }
            this.$nextTick(() => {
                this.loading = false
            })
        },
        fetchEntities () {
            if (process.env.NODE_ENV !== 'production') {
                // eslint-disable-next-line no-console
                console.error(('[mixins: fetchEntities] Please overide fetchEntities() method.'))
            }
        },
        getEntities () {
            if (process.env.NODE_ENV !== 'production') {
                // eslint-disable-next-line no-console
                console.error(('[mixins: EntitiesPage] Please overide getEntities() method.'))
            }
        },
        getNewEntities () {
            return this.newEntitiesDataSource
        },
        // eslint-disable-next-line no-unused-vars
        async create (_id) {
            if (process.env.NODE_ENV !== 'production') {
                // eslint-disable-next-line no-console
                console.error(('[mixins: EntitiesPage] Please overide create() method.'))
            }
        },
        // eslint-disable-next-line no-unused-vars
        async update (_id) {
            if (process.env.NODE_ENV !== 'production') {
                // eslint-disable-next-line no-console
                console.error(('[mixins: EntitiesPage] Please overide create() method.'))
            }
        },
        // eslint-disable-next-line no-unused-vars
        async delete (_id) {
            if (process.env.NODE_ENV !== 'production') {
                // eslint-disable-next-line no-console
                console.error(('[mixins: EntitiesPage] Please overide delete() method.'))
            }
        },
        // eslint-disable-next-line no-unused-vars
        search (_query) {
            if (process.env.NODE_ENV !== 'production') {
                // eslint-disable-next-line no-console
                console.error(('[mixins: EntitiesPage] Please overide search() method.'))
            }
        },
        updateQuery (value) {
            this.query = this.normalizeQuery(value)
        },
        normalizeQuery (query) {
            if (!query) return ''
            return query.trim()
        },
        handleQueryChange (e) {
            const { value } = e.target
            this.updateQuery(value)
            if (this.enableSearchRealTime) {
                this.onSeach(this.query)
            }
        },
        onSeach (query) {
            this.loading = true
            this.updateQuery(query)
            this.updateDataSource(this.search(this.query))
            this.updateNewEntityDataSource(this.search(this.query))
            this.loading = false
        },
        updateDataSource (data) {
            if (data === this.dataSource) return
            // Don't know why  [...data] is not shallow copy so it make DOM doesn't update properly.
            if (data) {
                this.dataSource = data.map(
                    (b, idx) => ({ index: idx, ...b }),
                )
            } else {
                this.dataSource = []
            }
        },
        updateNewEntityDataSource (data) {
            if (data === this.newEntitiesDataSource) return
            // Don't know why  [...data] is not shallow copy so it make DOM doesn't update properly.
            this.newEntitiesDataSource = data.map(
                (b, idx) => ({ index: idx, ...b }),
            )
        },
        createClickToEditRow (record) {
            return {
                on: {
                    click: () => {
                        this.openUpdateForm(record.id)
                    },
                },
            }
        },
        async onMutateEntity (mutateFuture, onSuccess) {
            this.loading = true
            const res = await mutateFuture
            this.loading = false
            if (res.isSuccess()) {
                onSuccess()
            }
        },
        async onCreateEntity (_entity) {
            this.onMutateEntity(this.create(_entity), () => this.closeCreateForm())
        },
        async onDelete (id) {
            this.onMutateEntity(this.delete(id), () => this.closeUpdateForm())
        },
        async onUpdate (data) {
            this.onMutateEntity(this.update(data), () => this.closeUpdateForm())
        },
    },
}
