<template>
    <a-descriptions
        title="รายละเอียด"
        size="small"
        layout="vertical"
        bordered>
        <a-descriptions-item
            label="
                    ราคาที่ลูกค้าจ่าย">
            {{ price !== null ?
                price : "-"
                | formatCurrency }}
            <template>
                {{ suffixLabelPrice }}
            </template>
        </a-descriptions-item>
        <a-descriptions-item label="สนามได้รับคงเหลือ">
            {{ price !== null ?
                priceWithOutFee
                : "-" | formatCurrency }}
        </a-descriptions-item>
        <a-descriptions-item label="ค่าธรรมเนียม">
            {{
                price !== null ?
                    fee
                    : "-" | formatCurrency }}
        </a-descriptions-item>
    </a-descriptions>
</template>

<script>

export default {
    props: {
        suffixLabelPrice: {
            type: String,
            default: '',
        },
        price: {
            type: Number,
            default: null,
        },
    },
    data () {
        return {
            percentFee: 5,
        }
    },
    computed: {
        priceWithOutFee () {
            return this.price - this.fee
        },
        fee () {
            return Math.ceil(this.price * (this.percentFee / 100))
        },
    },
}
</script>
